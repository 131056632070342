import { RefObject } from 'react';
import { Section, Zeck } from '../../../types.ts';
import ZeckCover from '../../../design-system/zeck/ZeckCover.tsx';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import cx from 'classnames';
import styles from './SectionView.module.scss';
import useProvidesViewportHeight from './useProvidesViewportHeight.ts';
import { isSupplemental } from '../../../types/Section.ts';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.ts';
import AppendixCard from './AppendixCard.js';
import SectionView from './SectionView.js';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.js';
import Linkable from 'editor-content/html/Linkable.ts';

type ZeckViewProps = {
  zeck: Zeck;
  linkables: Linkable[];
  scrollContainerRef: RefObject<HTMLElement>;
  companyName: string;
  sectionsWithRef: ElementAndData<Section>[];
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
};

function ZeckView({
  zeck,
  sectionsWithRef,
  companyName,
  linkables,
  scrollContainerRef,
  zeckPrevoteCapability,
  zeckFinalizeVoteCapability,
}: ZeckViewProps) {
  const { isMobile } = useBreakpoints();
  useProvidesViewportHeight(scrollContainerRef);

  const tableOfContentsSectionsWithRef = sectionsWithRef.filter(
    (sectionWithRef) => !isSupplemental(sectionWithRef.data),
  );
  const appendixSectionsWithRef = sectionsWithRef.filter((sectionWithRef) =>
    isSupplemental(sectionWithRef.data),
  );

  return (
    <div
      className={cx(styles.sectionView, isMobile && styles.sectionView__mobile)}
    >
      <ZeckCover
        zeck={zeck}
        companyName={companyName}
        navigation={() => {
          if (!scrollContainerRef.current) {
            return;
          }
          scrollContainerRef.current.scrollTo({
            top: scrollContainerRef.current.clientHeight,
            behavior: 'smooth',
          });
        }}
      />

      {tableOfContentsSectionsWithRef.map(({ data: section, setEl }) => (
        <SectionView
          key={section.id}
          section={section}
          ref={setEl}
          linkables={linkables}
          preview={true}
          scrollContainerRef={scrollContainerRef}
          zeckPrevoteCapability={zeckPrevoteCapability}
          zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
        />
      ))}
      {appendixSectionsWithRef.length > 0 && <AppendixCard />}
      {appendixSectionsWithRef.map(({ data: section, setEl }) => (
        <SectionView
          key={section.id}
          preview={true}
          section={section}
          ref={setEl}
          linkables={linkables}
          scrollContainerRef={scrollContainerRef}
          zeckPrevoteCapability={zeckPrevoteCapability}
          zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
        />
      ))}
    </div>
  );
}

export default ZeckView;
