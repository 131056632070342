import { CommentContentNode } from 'editor-content/CommentContent.js';
import { v4 } from 'uuid';
import ZeckEditor from '../ZeckEditor/ZeckEditor.js';
import getSelectionFromContent from '../getSelectionFromContent.js';
import SelectionCommentWithActions from './SelectionCommentWithActions.js';
import removeHighlightFromContent from './removeHighlightFromContent.js';
import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditorSelection from '../ZeckEditorSelection.js';
import { Block } from 'editor-content/Block.js';
import { Editor } from 'editor-content/TextNode.js';

const useSelectionComments = ({
  editorState,
  setEditorState,
  selectionComments,
  createSelectionComment,
}: {
  editorState: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  };
  setEditorState: (editorState: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void;
  selectionComments: SelectionCommentWithActions[];
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
}) => {
  const createHighlightWithComment = async (content: CommentContentNode[]) => {
    const highlightId = v4();
    const newContent = ZeckEditor.addHighlight(
      editorState.content,
      editorState.selection,
      highlightId,
    );

    if (!newContent) return;

    const selection = getSelectionFromContent(newContent.content, highlightId);

    if (!selection) return;

    await createSelectionComment(highlightId, content, selection);
    setEditorState(newContent);
  };

  const addSelectionCommentToExistingHighlight = async (
    highlightId: string,
    content: CommentContentNode[],
  ) => {
    const selection = getSelectionFromContent(editorState.content, highlightId);

    if (!selection) {
      return;
    }

    await createSelectionComment(highlightId, content, selection);
  };

  const selectionCommentsWithDelete =
    selectionComments.map<SelectionCommentWithActions>((selectionComment) => ({
      ...selectionComment,
      actions: {
        ...selectionComment.actions,
        async remove() {
          const isLastComment =
            selectionComments.filter(
              ({ selectionId }) => selectionComment.selectionId === selectionId,
            ).length === 1;

          if (isLastComment) {
            setEditorState({
              content: removeHighlightFromContent(
                selectionComment.selectionId,
                editorState.content,
              ),
              selection: editorState.selection,
            });
          } else {
            await selectionComment.actions.remove();
          }
        },
      },
    }));

  return {
    onAdd: createHighlightWithComment,
    onAddToExisting: addSelectionCommentToExistingHighlight,
    selectionComments: selectionCommentsWithDelete,
  };
};

export default useSelectionComments;
