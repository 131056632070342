import { EditorContent } from '../../../edit/useEditorState.tsx';
import ZeckEditorSelection from '../../ZeckEditorSelection.ts';
import { User } from '../../../../../types.ts';
import getCurrentHighlightId from '../getCurrentHighlightId.ts';
import arrayIsNotEmpty from '../../../../../junkDrawer/arrayIsNotEmpty.ts';
import SelectionCommentsMenu from './SelectionCommentsMenu.tsx';
import HoverNextToPoint from '../../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import useDarkenHighlights from './useDarkenHighlights.ts';
import SelectionCommentWithActions from '../SelectionCommentWithActions.ts';
import { CommentContentNode } from 'editor-content/CommentContent.js';

type SelectionCommentsExperienceProps = {
  children?: never;
  selectionComments: SelectionCommentWithActions[];
  companyId: string;
  zeckId: string;
  sectionId: string;
  onAddSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
  ) => Promise<void>;
  documentRef: React.RefObject<HTMLElement>;
  editorContent: EditorContent;
  editorSelection: ZeckEditorSelection;
  user: User;
};

const SelectionCommentsExperience: React.FunctionComponent<
  SelectionCommentsExperienceProps
> = ({
  selectionComments,
  companyId,
  zeckId,
  sectionId,
  onAddSelectionComment,
  editorSelection,
  editorContent,
  user,
  documentRef,
}) => {
  const highlightIds = getCurrentHighlightId(editorContent, editorSelection);

  useDarkenHighlights(highlightIds);

  if (!arrayIsNotEmpty(highlightIds)) return null;

  const [highlightId] = highlightIds;

  return (
    <HoverNextToPoint
      usePortal
      getPoint={(childElement) => {
        const documentEl = documentRef.current;
        if (!documentEl) return [0, 0];

        const el = documentEl.querySelector(
          `[data-highlightids~="${highlightId}"]`,
        );

        if (!el) return [0, 0];

        const rect = el.getBoundingClientRect();
        const childRect = childElement.getBoundingClientRect();
        return [
          (rect.left + rect.right) / 2 - childRect.width / 2,
          rect.top + rect.height + 8,
        ];
      }}
    >
      <SelectionCommentsMenu
        user={user}
        key={highlightId}
        comments={selectionComments.filter(
          ({ selectionId }) => highlightId === selectionId,
        )}
        onPostComment={async (content) => {
          await onAddSelectionComment(highlightId, content);
        }}
        companyId={companyId}
        zeckId={zeckId}
        sectionId={sectionId}
      />
    </HoverNextToPoint>
  );
};

export default SelectionCommentsExperience;
