import EditZeckCover from './edit/zeckCover/EditZeckCover.tsx';
import { BrandKitResource } from '../../design-system/zeck/WithBrandKit.tsx';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import withTouchRedirect from './withTouchRedirect.tsx';
import EditPageNew from './edit/EditPage.tsx';
import useFetchZeck from './useFetchZeck.ts';
import useApi from '../../api/useApi.ts';
import getIdFromSlug from '../../getIdFromSlug.ts';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';
import { sectionEditPath } from '../../services/Paths.ts';
import useDocumentTitleFromResult from '../../junkDrawer/useDocumentTitleFromResult.js';

type CoverPageProps = {
  brandKitResource: BrandKitResource;
  userAndCompany: UserAndCompany;
};

function CoverPage({ userAndCompany }: CoverPageProps) {
  const { slug } = useRequiredParams('slug');
  const { fetchZeck } = useApi();
  const zeckResult = useFetchZeck(
    () => fetchZeck(getIdFromSlug(slug)),
    [slug, userAndCompany],
  );

  const navigate = useNavigate();

  useDocumentTitleFromResult(zeckResult);

  switch (zeckResult.type) {
    case 'loading':
      return null;
    case 'error':
      return <Navigate to="/404" />;
    case 'success':
    case 'mutating':
  }

  const zeck = {
    ...zeckResult.data,
    actions: {
      ...zeckResult.data.actions,
      async createSection(isSupplemental: boolean) {
        const newSection =
          await zeckResult.data.actions.createSection(isSupplemental);
        navigate(sectionEditPath(newSection, zeck.companyId));
        return newSection;
      },
    },
  };

  return (
    <EditPageNew
      {...{
        isSaved: false,
        zeck,
        onRenameActiveSection: () => {
          // This is not a section that can be renamed
        },
      }}
    >
      <EditZeckCover userAndCompany={userAndCompany} zeck={zeck} />
    </EditPageNew>
  );
}

export default withTouchRedirect(CoverPage);
