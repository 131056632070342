import { CompanyLite, User } from '../../../types.ts';
import useFetchMeetingMinutes from '../useFetchMeetingMinutes.ts';
import { useRequiredParams } from '../../../routing/useRequiredParams.ts';
import { foldResult, pipe } from '../../../result/Result.js';
import MeetingMinutesLoading from '../MeetingMinutesLoading.tsx';
import { Navigate } from 'react-router-dom';
import MeetingMinutesView from '../../../meetingMinutes/MeetingMinutesView.tsx';
import PrintInstructions from '../../../design-system/organisms/PrintInstructions.tsx';
import styles from './MeetingMinutesPrintPage.module.scss';
import ButtonWithIcon from '../../../design-system/atoms/ButtonWithIcon.tsx';
import { isSignedMinutes } from '../MeetingMinutes.ts';
import MeetingMinutesSignature from '../../../meetingMinutes/MeetingMinutesSignature.tsx';

type MeetingMinutesPrintPageProps = {
  company: CompanyLite;
  user: User;
};
const MeetingMinutesPrintPage: React.FC<MeetingMinutesPrintPageProps> = () => {
  const { meetingMinutesId } = useRequiredParams('meetingMinutesId');
  const { result } = useFetchMeetingMinutes(meetingMinutesId);

  return pipe(
    result,
    foldResult({
      loading: () => <MeetingMinutesLoading />,
      error: () => <Navigate to="/404" />,
      success: (meetingMinutes) => (
        <div className={styles.container}>
          <PrintInstructions>
            <ButtonWithIcon
              iconName="print"
              size="medium"
              color="primary"
              position="left"
              onClick={() => {
                window.print();
              }}
              className={styles.printButton}
            >
              Print
            </ButtonWithIcon>
          </PrintInstructions>
          <MeetingMinutesView
            meetingMinutes={meetingMinutes}
            additionalContent={
              isSignedMinutes(meetingMinutes) ? (
                <MeetingMinutesSignature
                  signature={meetingMinutes.signatures[0]}
                />
              ) : null
            }
          />
        </div>
      ),
    }),
  );
};

export default MeetingMinutesPrintPage;
