import { EditorContent } from '../edit/useEditorState.js';
import ZeckEditorSelection from './ZeckEditorSelection.js';
import { ZeckEditorState } from './ZeckEditor/ZeckEditor.js';
import isArray from 'lodash/isArray.js';
import mapValues from 'lodash/mapValues.js';
import isFunction from 'lodash/isFunction.js';
import isObject from 'lodash/isObject.js';

type ZeckEditorAction<T extends unknown[]> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => ZeckEditorState;

type ZeckEditorActionWithEffect<T extends unknown[], ReturnType> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => [ZeckEditorState, ReturnType];

type MaybeHandledZeckEditorActionWithEffect<T extends unknown[], ReturnType> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => [ZeckEditorState, ReturnType] | void;

type MaybeHandledZeckEditorAction<T extends unknown[]> = (
  content: EditorContent,
  selection: ZeckEditorSelection,
  ...args: T
) => ZeckEditorState | void;

export type AdaptZeckEditorObject<Editor> = {
  [K in keyof Editor]: Editor[K] extends Record<string, unknown>
    ? AdaptZeckEditorObject<Editor[K]>
    : ZeckEditorActionToState<Editor[K]>;
};

export type ZeckEditorActionToState<Action> =
  Action extends ZeckEditorAction<infer Args>
    ? (...args: Args) => true
    : Action extends MaybeHandledZeckEditorAction<infer Args>
      ? (...args: Args) => true | void
      : Action extends ZeckEditorActionWithEffect<infer Args, infer ReturnType>
        ? (...args: Args) => ReturnType
        : Action extends MaybeHandledZeckEditorActionWithEffect<
              infer Args,
              infer ReturnType
            >
          ? (...args: Args) => ReturnType | void
          : unknown;

export const adaptZeckEditorObject = (
  state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  },
  onChange: (state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void,
) => {
  function mapValuesToEditorState(value: unknown): unknown {
    if (isFunction(value)) {
      return adaptZeckEditorActionToReactState(state, onChange)(value);
    } else if (isObject(value)) {
      return mapValues(value, mapValuesToEditorState);
    } else {
      return value;
    }
  }

  return <Editor extends Record<string, unknown>>(
    editor: Editor,
  ): AdaptZeckEditorObject<Editor> =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // I have no idea how to make this happy
    mapValuesToEditorState(editor);
};

const adaptZeckEditorActionToReactState = (
  state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  },
  onChange: (state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void,
) => {
  function adaptZeckEditorActionGeneric<T extends unknown[]>(
    action: ZeckEditorAction<T>,
  ): (...args: T) => true;
  function adaptZeckEditorActionGeneric<T extends unknown[], ReturnType>(
    action: ZeckEditorActionWithEffect<T, ReturnType>,
  ): (...args: T) => ReturnType;
  function adaptZeckEditorActionGeneric<T extends unknown[], ReturnType>(
    action: MaybeHandledZeckEditorActionWithEffect<T, ReturnType>,
  ): (...args: T) => ReturnType | void;
  function adaptZeckEditorActionGeneric<T extends unknown[]>(
    action: MaybeHandledZeckEditorAction<T>,
  ): (...args: T) => true | void;
  function adaptZeckEditorActionGeneric<T extends unknown[], ReturnType>(
    action: (
      content: EditorContent,
      selection: ZeckEditorSelection,
      ...args: T
    ) => [ZeckEditorState, ReturnType] | ZeckEditorState | void,
  ): (...args: T) => ReturnType | true | void {
    // ReturnType | true | void is kind of a weird signature, but maybe it's okay for now

    return (...args) => {
      const result = action(state.content, state.selection, ...args);

      // not handled
      if (!result) return;

      // handled with effect
      if (isArray(result)) {
        const [newState, effect] = result;

        onChange(newState);

        return effect;
      }

      // handled with no effect
      onChange(result);

      return true;
    };
  }

  return adaptZeckEditorActionGeneric;
};

export default adaptZeckEditorActionToReactState;
