import React, { useState } from 'react';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import { compact } from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';
import AddingBlockMenuWithItems from './AddingBlockMenuWithItems.js';
import { AddBlockItem, ComplexBlockItem } from './AddBlockConfiguration.js';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.ts';
import { FocusedBlock } from '../actions/pressForwardSlash.ts';

type UIState =
  | { type: 'hovering' }
  | {
      type: 'addBlock';
      blockId: string;
    };

export const useAddBlock = <BlockType extends { id: string }>({
  blocksWithEl,
  leftGutterRef,
  onAddNewBlock,
  onReplaceNewBlock,
  onForwardSlash,
  onConfigureComplexBlock,
  addBlockOptions,
}: {
  blocksWithEl: ElementAndData<BlockType>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  onAddNewBlock: (targetIndex: number) => string;
  onReplaceNewBlock: (newBlock: BlockType[], targetBlockId: string) => void;
  onForwardSlash: () => FocusedBlock | void;
  onConfigureComplexBlock: () => void;
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
}) => {
  const [uiState, setUIState] = useState<UIState>({ type: 'hovering' });

  useDocumentEventListener(
    'keydown',
    (e) => {
      if ('/' === e.key) {
        const addBlockFocusBlock: FocusedBlock | void = onForwardSlash();
        if (addBlockFocusBlock) {
          setUIState({ type: 'addBlock', blockId: addBlockFocusBlock.blockId });
        }
      }
    },
    [onForwardSlash],
  );
  return {
    state: uiState,
    setState: setUIState,
    showHoverButton: uiState.type === 'hovering',
    showAddBlockMenu: uiState.type === 'addBlock',
    disableScroll: uiState.type === 'addBlock',
    onClickAddBlock: (hoveredIndex: number) => {
      const newBlockId = onAddNewBlock(hoveredIndex);
      setUIState({
        type: 'addBlock',
        blockId: newBlockId,
      });
    },
    blocksWithEl,
    leftGutterRef,
    onReplaceNewBlock,
    onConfigureComplexBlock,
    addBlockOptions,
  };
};

export type AddBlockBehavior<BlockType extends { id: string }> = ReturnType<
  typeof useAddBlock<BlockType>
>;

// just animating presence
// really this should be merged with AddingBlock
export const AddingBlockExperience = <BlockType extends { id: string }>({
  addBlockBehavior,
}: {
  addBlockBehavior: AddBlockBehavior<BlockType>;
}) => {
  return (
    <AnimatePresence>
      {addBlockBehavior.state.type === 'addBlock' && (
        <motion.div
          key="picking"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <AddingBlock
            blockId={addBlockBehavior.state.blockId}
            blocksWithEl={addBlockBehavior.blocksWithEl}
            onReplaceNewBlock={addBlockBehavior.onReplaceNewBlock}
            onConfigureComplexBlock={addBlockBehavior.onConfigureComplexBlock}
            addBlockOptions={addBlockBehavior.addBlockOptions}
            onClose={() => addBlockBehavior.setState({ type: 'hovering' })}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

type AddBlockUIState =
  | {
      type: 'picking';
      blockId: string;
    }
  | {
      type: 'adding';
      blockId: string;
      blockType: string;
    };

const AddingBlock = <BlockType extends { id: string }>({
  blockId,
  blocksWithEl,
  onReplaceNewBlock,
  onConfigureComplexBlock,
  addBlockOptions,
  onClose,
}: {
  blockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  onReplaceNewBlock: (newBlock: BlockType[], targetBlockId: string) => void;
  onConfigureComplexBlock: () => void;
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onClose: () => void;
}) => {
  const [uiState, setUIState] = useState<AddBlockUIState>({
    type: 'picking',
    blockId,
  });

  switch (uiState.type) {
    case 'picking': {
      return (
        <AddingBlockMenuWithItems
          blocksWithEl={blocksWithEl}
          addBlockOptions={addBlockOptions}
          targetBlockId={uiState.blockId}
          onCancel={onClose}
          onPickBlockTypeComplex={(blockType) => {
            setUIState({
              type: 'adding',
              blockId: uiState.blockId,
              blockType,
            });
            onConfigureComplexBlock();
          }}
          onPickBlockTypeSimple={(content) => {
            onReplaceNewBlock(content, uiState.blockId);
            onClose();
          }}
        />
      );
    }
    case 'adding': {
      return (
        compact(addBlockOptions)
          .find(
            (blockConfig): blockConfig is ComplexBlockItem<BlockType> =>
              blockConfig.type === 'complex-block' &&
              blockConfig.blockType === uiState.blockType,
          )
          ?.renderAdding({
            onAdd: (block) => {
              onReplaceNewBlock(block, uiState.blockId);
              onClose();
            },
            onCancel: onClose,
          }) ?? null
      );
    }
  }
};
