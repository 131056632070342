import initials from 'initials';
import { MaybePendingUser } from '../../types.ts';
import styles from './PersonView.module.scss';
import cx from 'classnames';

export type PersonViewProps = {
  user: MaybePendingUser;
};

export default function PersonView({ user }: PersonViewProps) {
  if (user.pending) {
    return (
      <div className={cx(styles.personView, styles.personView_pending)}>
        <div className={styles.email}>{user.email} (Invited)</div>
      </div>
    );
  } else {
    const fullName = `${user.firstName} ${user.lastName}`;

    return (
      <div className={styles.personView}>
        <div className={styles.initials}>{initials(fullName)}</div>
        <div>
          <div className={styles.name}>{fullName}</div>
          <div className={styles.email}>{user.email}</div>
        </div>
      </div>
    );
  }
}
