import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

export type Integration = {
  id: string;
  credentialId: string;
  provider: 'google' | 'microsoft';
  providerMeta: {
    documentName: string;
    documentId: string;
    selectionName: string;
    selectionId: number;
    selectionType: 'sheet' | 'range';
    driveId?: string;
  };
};

export type IntegrationListItem = {
  id: string;
  credentialId: string;
  userId: string;
  providerMeta: Integration['providerMeta'];
};

export type IntegrationTypes =
  | 'google'
  | 'excelUpload'
  | 'excelOnedrive'
  | 'cartaCapTable';

export type IntegrationListItemData = {
  integrations: IntegrationListItem[];
  addIntegration: (integration: IntegrationListItem) => void;
  updateIntegration: (integration: IntegrationListItem) => void;
};

const createIntegrationApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createIntegration: async (
    integration: Omit<Integration, 'id'>,
  ): Promise<IntegrationListItem> => {
    return makeLambdaApiRequest(`/integration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(integration),
    });
  },
  updateIntegration: async (
    integration: Pick<Integration, 'id' | 'providerMeta'>,
  ): Promise<IntegrationListItem> => {
    return makeLambdaApiRequest(`/integration/${integration.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(integration),
    });
  },
  getSectionIntegrations: async (
    sectionId: string,
  ): Promise<IntegrationListItem[]> => {
    const response = await makeLambdaApiRequest(
      `/section/${sectionId}/integration`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.integrations;
  },
});

export default createIntegrationApi;
