import Linkable from 'editor-content/html/Linkable.js';
import { useState } from 'react';

type LinkDrawerState = { type: 'closed' } | { type: 'open'; content: Linkable };

const useLinkDrawer = () => {
  const [uiState, setUIState] = useState<LinkDrawerState>({
    type: 'closed',
  });

  return {
    closeDrawer() {
      setUIState({ type: 'closed' });
    },
    openDrawer(linkContent: Linkable) {
      setUIState({ type: 'open', content: linkContent });
    },
    drawerState: uiState,
  };
};

export default useLinkDrawer;
