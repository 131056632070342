import { contentSelection } from './selection/contentSelection/ContentSelection.js';
import replaceBlocksAt from './blocks/replaceBlocksAt.js';
import { EditorSelection } from './EditorSelection.js';
import { CoordinatorEvent, PublicEditorEvent } from './EditorEvents.js';
import {
  BlockEditorClassToBlock,
  BlockEditorInterface,
} from './BaseBlockEditor.js';
import matchSelectionType from './selection/matchSelectionType.js';
import { isTextSelection, textSelection } from './selection/TextSelection.js';
import { createHistoryStore, HistoryStore } from './HistoryStore.js';
import { blockSelection } from './selection/BlockSelection.js';
import { TextNode } from 'editor-content/TextNode.js';
import EditorData from '../pages/zeck/editor/EditorData.js';
import { Block } from 'editor-content/Block.js';
import replaceSelectionWith from '../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import arrayIs from '../junkDrawer/arrayIs.js';
import pressShiftArrowUp from './actions/pressShiftArrowUp.js';
import pressShiftArrowDown from './actions/pressShiftArrowDown.js';
import pressArrowDown from './actions/pressArrowDown.js';
import pressArrowUp from './actions/pressArrowUp.js';
import { EditorStateGeneric } from './EditorStateGeneric.js';
import pressEnter from './actions/pressEnter.js';
import pressShiftEnter from './actions/pressShiftEnter.js';
import AddBlockEditorActions from './addBlock/AddBlockEditorActions.js';
import pressDelete from '../pages/zeck/editor/BodyEditor/pressDelete.js';
import pressBackspace from '../pages/zeck/editor/BodyEditor/pressBackspace.js';
import pasteBlocks from './actions/copyPaste/pasteBlocks.js';
import pasteText from './actions/copyPaste/pasteText.js';
import cut from './actions/copyPaste/cut.ts';
import copy from './actions/copyPaste/copy.ts';
import pastePlaintext from './actions/copyPaste/pastePlaintext.js';
import { dropDraggedBlock } from './dragBlock/dropDraggedBlock.js';
import pressForwardSlash, {
  FocusedBlock,
} from './actions/pressForwardSlash.ts';
import { EditorConfiguration } from './EditorAction.js';

/**
 * This Editor is the coordinator in a PAC architecture.
 * A mediator between clients and block/editor specific objects that wrap the business logic
 */

export class EditorCoordinator<
  AvailableBlockEditors extends BlockEditorInterface<
    AvailableBlocks,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >,
  AvailableBlocks extends
    Block = BlockEditorClassToBlock<AvailableBlockEditors>,
  DefaultBlock extends AvailableBlocks = AvailableBlocks,
> {
  #contentSubscriptions: Set<() => void> = new Set();

  #store: HistoryStore<AvailableBlocks[]>;

  #generateBlockEditor: (block: AvailableBlocks) => AvailableBlockEditors;
  createDefaultBlock: (content: TextNode[]) => DefaultBlock;
  #getEditorConfig: () => EditorConfiguration<
    AvailableBlocks,
    AvailableBlockEditors
  >;

  #turnInto: (
    block: AvailableBlocks,
    type: AvailableBlocks['type'],
  ) => AvailableBlocks | void;

  constructor(
    initialState: {
      content: AvailableBlocks[];
      selection: EditorSelection;
    },
    configuration: {
      createDefaultBlock: (content: TextNode[]) => DefaultBlock;
      generateBlockEditor: (block: AvailableBlocks) => AvailableBlockEditors;
      turnInto: (
        block: AvailableBlocks,
        type: AvailableBlocks['type'],
      ) => AvailableBlocks | void;
    },
  ) {
    this.#store = createHistoryStore(initialState);
    this.#turnInto = configuration.turnInto;
    this.#generateBlockEditor = configuration.generateBlockEditor;
    this.createDefaultBlock = configuration.createDefaultBlock;
    this.#getEditorConfig = () => configuration;
  }

  getState() {
    return this.#store.get();
  }

  setState(newState: {
    content: AvailableBlocks[];
    selection: EditorSelection;
  }) {
    this.#store.set(newState);
    this.#notifyContentSubscribers();
  }

  cut(): void | EditorData<AvailableBlocks> {
    const result = cut(this.#getEditorConfig())(this.getState());
    if (!result) return;
    this.#applyActionResult(result[0]);
    return result[1];
  }

  copy(): void | EditorData<AvailableBlocks> {
    return copy<AvailableBlocks>(this.#generateBlockEditor)(this.getState());
  }

  pressForwardSlash(): void | FocusedBlock {
    const result = pressForwardSlash<AvailableBlocks>(
      this.#generateBlockEditor,
    )(this.getState());
    if (!result) return;
    const [content, focusedBlock] = result;
    this.#applyActionResult(content);
    return focusedBlock;
  }

  subscribeToContentChanges(callback: () => void) {
    this.#contentSubscriptions.add(callback);
    return () => {
      this.#contentSubscriptions.delete(callback);
    };
  }

  #notifyContentSubscribers() {
    for (const callback of this.#contentSubscriptions) {
      callback();
    }
  }

  #applyActionResult(result: void | EditorStateGeneric<AvailableBlocks>) {
    if (!result) return false;
    this.#store.set(result);
    this.#notifyContentSubscribers();

    return true;
  }

  dispatch = (
    event:
      | PublicEditorEvent<AvailableBlocks>
      | CoordinatorEvent<AvailableBlocks>,
  ): boolean => {
    const currentState = this.#store.get();
    switch (event.type) {
      case 'selection': {
        this.#store.set({
          content: currentState.content,
          selection: event.data,
        });
        this.#notifyContentSubscribers();
        return true;
      }
      case 'replaceBlocks': {
        if (isTextSelection(event.data.contentPatch.selection)) {
          this.#store.set({
            content: replaceBlocksAt(
              currentState.content,
              event.data.contentPatch.contentSubset,
              event.data.index,
              event.data.blocksToReplace,
            ),
            selection: {
              index: event.data.index + event.data.contentPatch.selection.index,
              offset: event.data.contentPatch.selection.offset,
            },
          });
        } else {
          this.#store.set({
            content: replaceBlocksAt(
              currentState.content,
              event.data.contentPatch.contentSubset,
              event.data.index,
              event.data.blocksToReplace,
            ),
            selection: event.data.contentPatch.selection,
          });
        }

        this.#notifyContentSubscribers();
        return true;
      }
      case 'insertEmptyBlock': {
        this.#store.set({
          content: replaceBlocksAt(
            currentState.content,
            [this.createDefaultBlock([])],
            event.data.index,
            0,
          ),
          selection: {
            index: event.data.index,
            offset: contentSelection(0),
          },
        });

        this.#notifyContentSubscribers();
        return true;
      }
      case 'undo': {
        this.#store.undo();
        this.#notifyContentSubscribers();
        return true;
      }
      case 'redo': {
        this.#store.redo();
        this.#notifyContentSubscribers();
        return true;
      }
      case 'pasteBlocks': {
        return this.#applyActionResult(
          pasteBlocks(this.#getEditorConfig())(currentState, event.data),
        );
      }
      case 'pasteText': {
        return this.#applyActionResult(
          pasteText<AvailableBlocks>(this.#getEditorConfig())(
            currentState,
            event.data,
          ),
        );
      }
      case 'replaceSelectedContentWithPlaintext': {
        return this.#applyActionResult(
          pastePlaintext(this.#getEditorConfig())(
            currentState,
            event.data.content,
          ),
        );
      }
      case 'turnInto': {
        if (currentState.selection == null) return false;

        const result = replaceSelectionWith(currentState, {
          textSelection: (selectedBlock, selection) => {
            const newBlock = this.#turnInto(
              selectedBlock,
              event.data.blockType,
            );
            if (!newBlock) return;

            return {
              contentSubset: [newBlock],
              selection: textSelection(0, selection),
            };
          },
          blockSelection: (selectedBlocks) => {
            const newBlocks = selectedBlocks.map((selectedBlock) =>
              this.#turnInto(selectedBlock, event.data.blockType),
            );

            if (
              arrayIs(newBlocks, (block): block is AvailableBlocks => !!block)
            ) {
              return {
                contentSubset: newBlocks,
                selection: blockSelection(0, newBlocks.length - 1),
              };
            }
            return;
          },
        });

        if (!result) return false;

        this.#store.set(result);
        this.#notifyContentSubscribers();

        return true;
      }
      case 'toggleHighlight': {
        const result = replaceSelectionWith(currentState, {
          textSelection: (selectedBlock, selection) => {
            const targetBlockEditor = this.#generateBlockEditor(selectedBlock);

            return targetBlockEditor.toggleHighlight(selection);
          },
          blockSelection: () => {
            return;
          },
        });

        if (!result) return false;

        this.#store.set(result);
        this.#notifyContentSubscribers();

        return true;
      }
      case 'pressShiftArrowUp': {
        return this.#applyActionResult(
          pressShiftArrowUp(this.#getEditorConfig())(currentState),
        );
      }
      case 'pressShiftArrowDown': {
        return this.#applyActionResult(
          pressShiftArrowDown(this.#getEditorConfig())(currentState),
        );
      }
      case 'pressArrowUp': {
        return this.#applyActionResult(
          pressArrowUp(this.#getEditorConfig())(
            currentState,
            event.data.isOnFirstLineOfBlock,
            event.data.fancyNavUp,
          ),
        );
      }
      case 'pressArrowDown': {
        return this.#applyActionResult(
          pressArrowDown(this.#getEditorConfig())(
            currentState,
            event.data.isOnLastLineOfBlock,
            event.data.fancyNavDown,
          ),
        );
      }
      case 'pressEnter': {
        return this.#applyActionResult(
          pressEnter(this.#getEditorConfig())(currentState),
        );
      }
      case 'delete': {
        return this.#applyActionResult(
          pressDelete<AvailableBlocks>(
            (block) => this.#generateBlockEditor(block).Delete,
            this.createDefaultBlock,
          )(currentState),
        );
      }
      case 'pressBackspace': {
        const actionResult = pressBackspace<AvailableBlocks>(
          (block) => this.#generateBlockEditor(block).Backspace,
          this.createDefaultBlock,
        )(currentState);

        const adaptedActionResult =
          actionResult && actionResult.type === 'merge'
            ? undefined
            : actionResult;

        return this.#applyActionResult(adaptedActionResult);
      }
      case 'pressShiftEnter': {
        return this.#applyActionResult(
          pressShiftEnter(this.#getEditorConfig())(currentState),
        );
      }
      case 'addNewBlock': {
        const [newState] = AddBlockEditorActions.addNewBlock(
          this.#getEditorConfig(),
        )(currentState, event.data.targetIndex, event.data.newBlock);
        return this.#applyActionResult(newState);
      }
      case 'replaceNewBlock': {
        const [newState] = AddBlockEditorActions.replaceNewBlock(
          this.#getEditorConfig(),
        )(currentState, event.data.newContent, event.data.targetBlockId);
        return this.#applyActionResult(newState);
      }
      case 'dropDraggedBlock': {
        return this.#applyActionResult(
          dropDraggedBlock(
            currentState,
            event.data.startIndex,
            event.data.endIndex,
          ),
        );
      }
    }

    return matchSelectionType({
      blockSelectionAction: () => {
        // unhandled events
        return false;
      },
      textSelectionAction: (selection) => {
        const currentState = this.#store.get();
        const targetBlock = currentState.content[selection.index];
        if (targetBlock) {
          const targetBlockEditor = this.#generateBlockEditor(targetBlock);
          targetBlockEditor.setEditorDispatch(this.dispatch);

          switch (event.type) {
            case 'pressArrowLeft': {
              return targetBlockEditor.dispatch(
                {
                  type: 'pressArrowLeft',
                  previousBlock: currentState.content[selection.index - 1],
                },
                selection,
              );
            }
            case 'pressArrowRight': {
              return targetBlockEditor.dispatch(
                {
                  type: 'pressArrowRight',
                  nextBlock: currentState.content[selection.index + 1],
                },
                selection,
              );
            }
            default: {
              return targetBlockEditor.dispatch(event, selection);
            }
          }
        } else {
          console.log(
            'event fired on nonexistent target.  index: , editors: ',
            selection.index,
            currentState.content,
          );
        }

        // unhandled events
        return false;
      },
    })(currentState.selection);
  };
}
