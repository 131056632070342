import React from 'react';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { TableBlock } from 'editor-content/TableBlock.js';
import TableFromBlock from '../../../../design-system/zeck/TableFromBlock.tsx';
import {
  HorizontalShadowScroll,
  WideWidthBlockContainer,
} from '../../../../design-system/zeck/TableView.tsx';

type TableEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
} & BlockActions<TableBlock>;

export default React.forwardRef<HTMLElement, TableEditableWithKeyboardProps>(
  function TableEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      onNavLeft,
      onNavRight,
      onEnter,
      isInBlockSelection,
      'data-testid': dataTestid,
    },
    forwardedRef,
  ) {
    return (
      <WideWidthBlockContainer>
        <SelectableDiv
          square
          className={className}
          onSelect={onSelect}
          internalSelection={selection}
          isInBlockSelection={isInBlockSelection}
          onKeyDown={callHandlers([
            handleNonTextBlockKeyEvents({
              onEnter,
              onNavLeft,
              onNavRight,
            }),
          ])}
          data-testid={dataTestid}
          ref={mergeRefs([forwardedRef])}
        >
          <HorizontalShadowScroll>
            <TableFromBlock block={block} />
          </HorizontalShadowScroll>
        </SelectableDiv>
      </WideWidthBlockContainer>
    );
  },
);
