import { getSelectionFormat } from '../../../../editor/selection/contentSelection/getSelectionFormat.ts';
import FormattingMenuWithComments from './TextFormattingMenuWithComments.tsx';
import { ToolbarGroup } from '../../../../design-system/organisms/Toolbar.tsx';
import {
  BoldButton,
  ItalicsButton,
  UnderlineButton,
} from './FormatTextButton.tsx';
import IndentFormattingActions from './IndentFormattingActions.tsx';
import { Block, isTextBlock, TextBlock } from 'editor-content/Block.js';
import ContentSelection, {
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { Editor, TextFormat } from 'editor-content/TextNode.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import Linkable from 'editor-content/html/Linkable.ts';

type TextSelectionFormattingMenuProps = {
  block: Block;
  selection: ContentSelection | null;
  companyId: string;
  onIndent: (amount: -1 | 1) => void;
  linkables: Linkable[];
  onToggleFormat: (formatName: keyof TextFormat) => void;
  onAddLink: (link: Editor.LinkType) => void;
  onTurnInto(type: TextBlock['type']): void;
  onAddSelectionComment: (content: CommentContentNode[]) => Promise<void>;
  zeckId: string;
  sectionId: string;
};

const TextSelectionFormattingMenu: React.FC<
  TextSelectionFormattingMenuProps
> = ({
  block,
  selection,
  companyId,
  onIndent,
  linkables,
  onAddLink,
  onAddSelectionComment,
  zeckId,
  sectionId,
  onTurnInto,
  onToggleFormat,
}) => {
  if (!isTextBlock(block)) return null;
  if (!selection || isCollapsed(selection)) return null;

  const format = getSelectionFormat(block, selection);

  switch (block.type) {
    case 'paragraph': {
      return (
        <FormattingMenuWithComments
          linkables={linkables}
          onAddLink={onAddLink}
          onAddSelectionComment={onAddSelectionComment}
          companyId={companyId}
          zeckId={zeckId}
          sectionId={sectionId}
          {...{
            linkEnabled: true,
            turnIntoEnabled: true,
            blockDisplayName: 'text',
            otherActions: (
              <ToolbarGroup>
                <BoldButton {...{ format, onToggleFormat }} />
                <ItalicsButton {...{ format, onToggleFormat }} />
                <UnderlineButton {...{ format, onToggleFormat }} />
              </ToolbarGroup>
            ),
          }}
          onTurnInto={onTurnInto}
        />
      );
    }
    case 'label': {
      return (
        <FormattingMenuWithComments
          linkables={linkables}
          onAddLink={onAddLink}
          onAddSelectionComment={onAddSelectionComment}
          companyId={companyId}
          zeckId={zeckId}
          sectionId={sectionId}
          {...{
            linkEnabled: false,
            turnIntoEnabled: true,
            blockDisplayName: 'label',
            otherActions: null,
          }}
          onTurnInto={onTurnInto}
        />
      );
    }
    case 'bulleted-list-item': {
      return (
        <FormattingMenuWithComments
          linkables={linkables}
          onAddLink={onAddLink}
          onAddSelectionComment={onAddSelectionComment}
          companyId={companyId}
          zeckId={zeckId}
          sectionId={sectionId}
          blockDisplayName={'bulleted list'}
          turnIntoEnabled={true}
          linkEnabled={true}
          otherActions={
            <>
              <ToolbarGroup>
                <BoldButton {...{ format, onToggleFormat }} />
                <ItalicsButton {...{ format, onToggleFormat }} />
                <UnderlineButton {...{ format, onToggleFormat }} />
              </ToolbarGroup>
              <ToolbarGroup>
                <IndentFormattingActions value={block} onIndent={onIndent} />
              </ToolbarGroup>
            </>
          }
          onTurnInto={onTurnInto}
        />
      );
    }
    case 'numbered-list-item': {
      return (
        <FormattingMenuWithComments
          linkables={linkables}
          onAddLink={onAddLink}
          onAddSelectionComment={onAddSelectionComment}
          companyId={companyId}
          zeckId={zeckId}
          sectionId={sectionId}
          {...{
            linkEnabled: true,
            turnIntoEnabled: true,
            blockDisplayName: 'numbered list',
            otherActions: (
              <>
                <ToolbarGroup>
                  <BoldButton {...{ format, onToggleFormat }} />
                  <ItalicsButton {...{ format, onToggleFormat }} />
                  <UnderlineButton {...{ format, onToggleFormat }} />
                </ToolbarGroup>
              </>
            ),
          }}
          onTurnInto={onTurnInto}
        />
      );
    }
    case 'heading': {
      return (
        <FormattingMenuWithComments
          linkables={linkables}
          onAddLink={onAddLink}
          onAddSelectionComment={onAddSelectionComment}
          companyId={companyId}
          zeckId={zeckId}
          sectionId={sectionId}
          {...{
            linkEnabled: true,
            turnIntoEnabled: true,
            blockDisplayName: 'heading',
            otherActions: (
              <>
                <ToolbarGroup>
                  <ItalicsButton {...{ format, onToggleFormat }} />
                  <UnderlineButton {...{ format, onToggleFormat }} />
                </ToolbarGroup>
              </>
            ),
          }}
          onTurnInto={onTurnInto}
        />
      );
    }
  }
};

export default TextSelectionFormattingMenu;
