import { Block } from 'editor-content/Block.ts';
import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import { EditorContent } from '../../pages/zeck/edit/useEditorState.js';

export type AiOptimizeSectionSuccessResponse = {
  data?: {
    body?: Block[];
    metadata: {
      jobId: string;
    };
  };
};

export type AiOptimizeSectionErrorResponse = {
  error: string;
};

type AiOptimizeSectionResponse =
  | AiOptimizeSectionSuccessResponse
  | AiOptimizeSectionErrorResponse;

export const isAiOptimizeSectionErrorResponse = (
  response: AiOptimizeSectionResponse,
): response is AiOptimizeSectionErrorResponse =>
  response.hasOwnProperty('error');

const createAiApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  aiOptimizeSection: async (
    sectionId: string,
    editorContent: EditorContent,
  ): Promise<AiOptimizeSectionResponse> => {
    const jobResponse = await makeLambdaApiRequest(
      `/section/${sectionId}/ai-optimize`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ section: editorContent }),
      },
    );

    const s3Url = jobResponse.jobUrl;

    let jobResoponse = null;
    while (!jobResoponse) {
      await sleep(1000);
      const response = await fetch(s3Url);
      if (response.status !== 404) {
        jobResoponse = await response.json();
      }
    }

    // todo a codec or something
    return jobResoponse;
  },
});

export default createAiApi;

async function sleep(time: number) {
  await new Promise((resolve) => setTimeout(resolve, time));
}
