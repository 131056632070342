import React from 'react';
import cx from 'classnames';
import styles from '../../design-system/zeck/File.module.scss';
import File from '../../design-system/zeck/File.tsx';
import useApi from '../../api/useApi.ts';
import { FileBlock } from 'editor-content/FileBlock.js';

type FileProps = {
  className?: string;
  block: FileBlock;
};

const FileWithDownload = React.forwardRef<HTMLButtonElement, FileProps>(
  function FileWithDownload({ className, block }, forwardedRef) {
    const { getFile } = useApi();

    return (
      <button
        id={block.id}
        className={cx(styles.fileWithDownload, className)}
        onClick={async () => {
          const fileGetData = await getFile(block.guid);

          if (fileGetData?.readUrl) {
            window.location.href = fileGetData.readUrl;
          }
        }}
        ref={forwardedRef}
      >
        <File
          {...{
            clickable: true,
            filename: block.filename,
          }}
        />
      </button>
    );
  },
);

export default FileWithDownload;
