import {
  bulletedListItemBlock,
  BulletedListItemBlock,
  createBulletedListItemBlock,
} from 'editor-content/BulletedListItemBlock.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import { getTextFromBlock, TextBlock } from 'editor-content/Block.js';

import { ContentPatch } from '../../ContentPatch.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';

function isTextBlockEmpty(selectedBlock: TextBlock) {
  return getTextFromBlock(selectedBlock).length === 0;
}

export default function bulletedListItemPressEnterStrategy<B>(
  block: BulletedListItemBlock,
  selection: ContentSelection,
  createEmptyBlock: (id: string) => B,
): ContentPatch<[B] | [BulletedListItemBlock, BulletedListItemBlock]> {
  if (isTextBlockEmpty(block)) {
    return {
      contentSubset: [createEmptyBlock(block.id)],
      selection: {
        index: 0,
        offset: {
          anchorOffset: 0,
          focusOffset: 0,
        },
      },
    };
  } else {
    const [beforeSelectionTextNodes, , afterSelectionTextNodes] =
      splitTextNodesFromContentSelection(block.content, selection);

    return {
      contentSubset: [
        bulletedListItemBlock(block.id, beforeSelectionTextNodes, block.indent),
        createBulletedListItemBlock(afterSelectionTextNodes, block.indent),
      ],
      selection: {
        index: 1,
        offset: {
          anchorOffset: 0,
          focusOffset: 0,
        },
      },
    };
  }
}
