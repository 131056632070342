import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.js';
import { TextSelection } from '../../../editor/selection/TextSelection.js';
import { BlockSelection } from '../../../editor/selection/BlockSelection.js';

export type ZeckTitleSelection = {
  target: 'title';
} & ContentSelection;

export type ZeckHeadlineSelection = {
  target: 'headline';
} & ContentSelection;

export type ZeckBodySelection = {
  target: 'body';
} & (TextSelection | BlockSelection);

export const isBodySelection = (
  selection: ZeckEditorSelection,
): selection is ZeckBodySelection => selection?.target === 'body';
export const isHeadlineSelection = (
  selection: ZeckEditorSelection,
): selection is ZeckHeadlineSelection => selection?.target === 'headline';
export const isTitleSelection = (
  selection: ZeckEditorSelection,
): selection is ZeckTitleSelection => selection?.target === 'title';

type ZeckEditorSelection =
  | ZeckTitleSelection
  | ZeckHeadlineSelection
  | ZeckBodySelection
  | null;

export const getTitleSelection = (
  selection: ZeckEditorSelection,
): ZeckTitleSelection | null => {
  if (selection?.target !== 'title') return null;

  return selection;
};

export const getBodySelection = (
  selection: ZeckEditorSelection,
): ZeckBodySelection | null => {
  if (selection?.target !== 'body') return null;

  return selection;
};

export const getHeadlineSelection = (
  selection: ZeckEditorSelection,
): ZeckHeadlineSelection | null => {
  if (selection?.target !== 'headline') return null;

  return selection;
};

export default ZeckEditorSelection;
