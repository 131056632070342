import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import AddingBlockMenu from './AddingBlockMenu.js';
import { compact } from 'lodash';
import AddBlockMenuSeparator from './AddBlockMenuSeparator.js';
import AddBlockMenuItem from './AddBlockMenuItem.js';
import { AddBlockItem } from './AddBlockConfiguration.js';
import { useState } from 'react';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.ts';

const AddingBlockMenuWithItems = <BlockType extends { id: string }>({
  targetBlockId,
  blocksWithEl,
  addBlockOptions,
  onPickBlockTypeSimple,
  onPickBlockTypeComplex,
  onCancel,
}: {
  targetBlockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onPickBlockTypeSimple: (newContent: BlockType[]) => void;
  onPickBlockTypeComplex: (blockType: string) => void;
  onCancel: () => void;
}) => {
  const [cursor, setCursor] = useState<number>(0);
  const compactedOptions = compact(addBlockOptions);

  useDocumentEventListener(
    'keydown',
    (e) => {
      const option = compactedOptions[cursor];
      const isSkipBlockItem = (cursor: number): boolean => {
        const option = compactedOptions[cursor];
        return !!option && option.type === 'separator';
      };
      switch (e.key) {
        case 'ArrowDown':
          setCursor((cursor) => {
            if (cursor === compactedOptions.length - 1) {
              return 0;
            }
            return isSkipBlockItem(cursor + 1) ? cursor + 2 : cursor + 1;
          });
          e.stopPropagation();
          break;
        case 'ArrowUp':
          setCursor((cursor) => {
            if (cursor === 0) {
              return compactedOptions.length - 1;
            }
            return isSkipBlockItem(cursor - 1) ? cursor - 2 : cursor - 1;
          });
          e.stopPropagation();
          break;
        case 'Enter':
          if (option && option.type === 'block') {
            onPickBlockTypeSimple(option.createNewBlock());
          } else if (option && option.type === 'complex-block') {
            onPickBlockTypeComplex(option.blockType);
          }
          e.preventDefault();
          e.stopPropagation();
          break;
        case 'Escape': // all other are keys cause an onCancel
          onCancel();
          e.stopPropagation();
          break;
      }
    },
    [
      cursor,
      addBlockOptions,
      onPickBlockTypeSimple,
      onPickBlockTypeComplex,
      onCancel,
    ],
    true, // capture
  );

  return (
    <AddingBlockMenu
      blocksWithEl={blocksWithEl}
      targetBlockId={targetBlockId}
      onCancel={onCancel}
      addableBlocks={compactedOptions.map((value, index) => {
        const selected = cursor == index;
        switch (value.type) {
          case 'separator':
            return (
              <AddBlockMenuSeparator key={value.label}>
                {value.label}
              </AddBlockMenuSeparator>
            );

          case 'block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeSimple(value.createNewBlock());
                }}
                label={value.label}
                description={value.description}
                selected={selected}
              />
            );
          case 'complex-block':
            return (
              <AddBlockMenuItem
                key={value.label}
                iconName={value.iconName}
                onClick={() => {
                  onPickBlockTypeComplex(value.blockType);
                }}
                label={value.label}
                description={value.description}
                selected={selected}
              />
            );
        }
      })}
    />
  );
};

export default AddingBlockMenuWithItems;
