import { ToolbarGroup } from '../../../../design-system/organisms/Toolbar.tsx';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import { BulletedListItemBlock } from 'editor-content/BulletedListItemBlock.js';

type IndentFormattingActionsProps = {
  value: BulletedListItemBlock;
  onIndent: (amount: -1 | 1) => void;
};

const IndentFormattingActions: React.VFC<IndentFormattingActionsProps> = ({
  value,
  onIndent,
}) => {
  return (
    <ToolbarGroup>
      {value.indent > 0 ? (
        <IconButton
          {...{
            name: 'indentDecrease',
            type: 'dark',
            'aria-label': 'decrease text indent',
            onClick: () => onIndent(-1),
          }}
        />
      ) : (
        <IconButton
          {...{
            name: 'indentIncrease',
            type: 'dark',
            'aria-label': 'increase text indent',
            onClick: () => onIndent(1),
          }}
        />
      )}
    </ToolbarGroup>
  );
};

export default IndentFormattingActions;
