import { AgendaBlock } from './AgendaBlock.js';
import { baseBlock, createBlock, generateBlockId } from './BaseBlock.js';
import BaseTextBlock from './BaseTextBlock.js';
import {
  BulletedListItemBlock,
  createBulletedListItemBlock,
} from './BulletedListItemBlock.js';
import { CartaCapTableBlock } from './CartaBlock.js';
import { DividerBlock } from './DividerBlock.js';
import normalizeTextNodes from './normalizeTextNodes.js';
import {
  createNumberedListItemBlock,
  NumberedListItemBlock,
} from './NumberedListItemBlock.js';
import { TableBlock } from './TableBlock.js';
import { getTextFromNodes, TextNode } from './TextNode.js';
import { VoteBlock } from './VoteBlock.js';

export * from './BulletedListItemBlock.js';

export * from './NumberedListItemBlock.js';

export * from './DividerBlock.js';
export * from './CartaBlock.js';

export type BlockContent = TextNode[];

export type HeadingBlock = {
  type: 'heading';
  id: string;
  content: BlockContent;
};

export const HeadingBlock = (id: string, content: BlockContent): HeadingBlock =>
  baseBlock('heading', id, { content });

export const createHeadingBlock = (content: BlockContent): HeadingBlock =>
  createBlock('heading', { content });

export type ParagraphBlock = {
  type: 'paragraph';
  id: string;
  content: BlockContent;
};

export const ParagraphBlock = (
  id: string,
  content: BlockContent,
): ParagraphBlock => baseBlock('paragraph', id, { content });

export const createParagraphBlock = (content: BlockContent): ParagraphBlock =>
  createBlock('paragraph', { content });

export type LabelBlock = {
  type: 'label';
  id: string;
  content: BlockContent;
};

export const LabelBlock = (id: string, content: BlockContent): LabelBlock =>
  baseBlock('label', id, { content });

export const createLabelBlock = (content: BlockContent): LabelBlock =>
  createBlock('label', { content });

export type TextBlock =
  | ParagraphBlock
  | LabelBlock
  | BulletedListItemBlock
  | NumberedListItemBlock
  | HeadingBlock;

export type ImageBlock = {
  type: 'image';
  id: string;
  guid: string;
  caption: string;
  width: 'column' | 'wide' | 'full-width';
  align: 'center' | 'left';
  dimensions?: { width: number; height: number };
};

export const ImageBlock = (
  id: string,
  guid: string,
  caption: string,
  width: ImageBlock['width'],
  align: ImageBlock['align'] = 'center',
  dimensions?: ImageBlock['dimensions'],
): ImageBlock =>
  baseBlock('image', id, {
    guid,
    caption,
    width,
    align,
    dimensions,
  });

export const createImageBlock = (
  guid: string,
  caption: string,
  width: ImageBlock['width'],
  align: ImageBlock['align'] = 'center',
  dimensions?: ImageBlock['dimensions'],
): ImageBlock =>
  createBlock('image', {
    guid,
    caption,
    width,
    align,
    dimensions,
  });

export type FileBlock = {
  type: 'file';
  id: string;
  filename: string;
  guid: string;
};

export const FileBlock = (
  id: string,
  guid: string,
  filename: string,
): FileBlock => baseBlock('file', id, { guid, filename });

export const createFileBlock = (guid: string, filename: string): FileBlock =>
  createBlock('file', { guid, filename });

export const isFileBlock = (block: Block): block is FileBlock =>
  block.type === 'file';

export const isImageBlock = (block: Block): block is ImageBlock =>
  block.type === 'image';

export const isVideoBlock = (block: Block): block is VideoBlock =>
  block.type === 'video';

export const isTextBlock = (block: Block): block is TextBlock => {
  switch (block.type) {
    case 'paragraph':
    case 'label':
    case 'bulleted-list-item':
    case 'numbered-list-item':
    case 'heading':
      return true;
    case 'image':
    case 'file':
    case 'vote':
    case 'agenda':
    case 'video':
    case 'divider':
    case 'table':
    case 'carta-cap-table':
      return false;
  }
  assertUnreachable(block);
};

export const getTextFromBlock = (block: TextBlock): string => {
  if (!isTextBlock(block)) {
    return '';
  }

  return getTextFromNodes(block.content);
};

export type VideoBlock = {
  id: string;
  type: 'video';
  url: string;
};

export const createVideoBlock = (url: string): VideoBlock =>
  createBlock('video', {
    url,
  });

export const VideoBlock = (id: string, url: string): VideoBlock =>
  baseBlock('video', id, { url });

export function newBlockOfType(
  block: ParagraphBlock,
  newContent: TextNode[],
): ParagraphBlock;
export function newBlockOfType(
  block: LabelBlock,
  newContent: TextNode[],
): LabelBlock;
export function newBlockOfType(
  block: BulletedListItemBlock,
  newContent: TextNode[],
): BulletedListItemBlock;
export function newBlockOfType(
  block: NumberedListItemBlock,
  newContent: TextNode[],
): NumberedListItemBlock;
export function newBlockOfType(
  block: HeadingBlock,
  newContent: TextNode[],
): HeadingBlock;
export function newBlockOfType(
  block: TextBlock,
  newContent: TextNode[],
): TextBlock;
export function newBlockOfType(block: TextBlock, newContent: TextNode[]) {
  const normalizedContent = normalizeTextNodes(newContent);
  switch (block.type) {
    case 'paragraph':
      return createParagraphBlock(normalizedContent);
    case 'label':
      return createLabelBlock(normalizedContent);
    case 'bulleted-list-item':
      return createBulletedListItemBlock(normalizedContent, block.indent);
    case 'numbered-list-item':
      return createNumberedListItemBlock(normalizedContent, block.indent);
    case 'heading':
      return createHeadingBlock(normalizedContent);
  }
}

export function newTextBlockOfType<BlockType extends BaseTextBlock>(
  block: BlockType,
  newContent: TextNode[],
): BlockType {
  return {
    ...block,
    id: generateBlockId(),
    content: normalizeTextNodes(newContent),
  };
}

export function updateTextBlock<T extends { content: TextNode[] }>(
  block: T,
  newContent: TextNode[],
): T {
  return {
    ...block,
    content: normalizeTextNodes(newContent),
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export type Block =
  | CartaCapTableBlock
  | TextBlock
  | ImageBlock
  | FileBlock
  | VoteBlock
  | AgendaBlock
  | VideoBlock
  | DividerBlock
  | TableBlock;
