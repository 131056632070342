import { isTextBlock, TextBlock } from 'editor-content/Block.js';
import { Editor, isHighlight, TextNode } from 'editor-content/TextNode.js';
import { EditorContent } from '../../edit/useEditorState.js';

function removeHighlightFromContent(
  highlightId: string,
  content: EditorContent,
) {
  const newBody = content.body.map((block) => {
    if (isTextBlock(block)) {
      return removeHighlightFromTextBlock(highlightId, block);
    }
    return block;
  });
  const newHeadline: TextNode[] = [];
  content.headline.forEach((textNode) => {
    if (isHighlight(textNode)) {
      const newNodes = unrollHighlight(highlightId, textNode);
      newHeadline.push(...newNodes);
    } else {
      newHeadline.push(textNode);
    }
  });

  return {
    title: content.title,
    headline: newHeadline,
    body: newBody,
  };
}

function removeHighlightFromTextBlock(
  highlightId: string,
  textBlock: TextBlock,
): TextBlock {
  const newContent: TextNode[] = [];
  textBlock.content.forEach((textNode) => {
    if (isHighlight(textNode)) {
      const newNodes = unrollHighlight(highlightId, textNode);
      newContent.push(...newNodes);
    } else {
      newContent.push(textNode);
    }
  });

  return {
    ...textBlock,
    content: newContent,
  };
}

function unrollHighlight(
  highlightId: string,
  highlightNode: Editor.Highlight,
): TextNode[] {
  let newNodes: TextNode[] = [];
  const filteredHighlightIds = highlightNode.ids.filter((id) => {
    return id !== highlightId;
  });
  if (filteredHighlightIds.length === 0) {
    newNodes = highlightNode.content;
  } else {
    newNodes.push({
      type: 'highlight',
      ids: filteredHighlightIds,
      content: highlightNode.content,
    });
  }

  return newNodes;
}

export default removeHighlightFromContent;
