import React, { SVGProps } from 'react';
import { cx } from 'styling';
import { styles } from '../styles.ts';

export function AiPencilIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(styles, props.className)}
    >
      <path
        d="M3.8875 9.09724L3.9275 9.01724C4.09417 8.69391 4.3575 8.43057 4.68083 8.26391L4.76083 8.22391C5.0775 8.06391 5.0775 7.61057 4.76083 7.44724L4.68083 7.40724C4.3575 7.24057 4.09417 6.97724 3.9275 6.65391L3.8875 6.57391C3.7275 6.25724 3.27417 6.25724 3.11083 6.57391L3.07083 6.65391C2.90417 6.97724 2.64083 7.24057 2.3175 7.40724L2.2375 7.44724C1.92083 7.60724 1.92083 8.06057 2.2375 8.22391L2.3175 8.26391C2.64083 8.43057 2.90417 8.69391 3.07083 9.01724L3.11083 9.09724C3.27083 9.41391 3.72417 9.41391 3.8875 9.09724Z"
        fill="#555770"
      />
      <path
        d="M13.4308 11.7806L13.3508 11.7406C13.0275 11.5739 12.7642 11.3106 12.5975 10.9872L12.5575 10.9072C12.3975 10.5906 11.9442 10.5906 11.7808 10.9072L11.7408 10.9872C11.5742 11.3106 11.3108 11.5739 10.9875 11.7406L10.9075 11.7806C10.5908 11.9406 10.5908 12.3939 10.9075 12.5572L10.9875 12.5972C11.3108 12.7639 11.5742 13.0272 11.7408 13.3506L11.7808 13.4306C11.9408 13.7472 12.3942 13.7472 12.5575 13.4306L12.5975 13.3506C12.7642 13.0272 13.0275 12.7639 13.3508 12.5972L13.4308 12.5572C13.7475 12.3972 13.7475 11.9439 13.4308 11.7806Z"
        fill="#555770"
      />
      <path
        d="M4.14083 4.92724L4.2475 4.98057C4.58083 5.15057 4.8475 5.41724 5.0175 5.75057L5.07083 5.85724C5.25417 6.21724 5.62083 6.44057 6.02417 6.44057C6.4275 6.44057 6.79417 6.21724 6.98083 5.85724L7.03417 5.75057C7.20417 5.41724 7.47083 5.15057 7.80417 4.98057L7.91083 4.92724C8.27083 4.74391 8.49417 4.37724 8.49417 3.97391C8.49417 3.57057 8.27083 3.20391 7.91083 3.01724L7.80417 2.96391C7.47083 2.79391 7.20417 2.52724 7.03417 2.19391L6.98083 2.08724C6.7975 1.72724 6.43083 1.50391 6.02417 1.50391C5.6175 1.50391 5.25417 1.72724 5.0675 2.08724L5.01417 2.19391C4.84417 2.52724 4.5775 2.79391 4.24417 2.96391L4.1375 3.01724C3.7775 3.20057 3.55417 3.56724 3.55417 3.97391C3.55417 4.38057 3.7775 4.74391 4.1375 4.93057L4.14083 4.92724ZM4.59417 3.90724L4.70083 3.85391C5.22417 3.58724 5.64083 3.17057 5.9075 2.64724L5.96083 2.54057C5.96083 2.54057 5.98083 2.50057 6.02417 2.50057C6.0675 2.50057 6.08417 2.52724 6.0875 2.54057L6.14083 2.64724C6.4075 3.17057 6.82417 3.58724 7.3475 3.85391L7.45417 3.90724C7.45417 3.90724 7.49417 3.92724 7.49417 3.97057C7.49417 4.01391 7.46417 4.03057 7.45417 4.03391L7.3475 4.08724C6.82417 4.35391 6.4075 4.77057 6.14083 5.29391L6.0875 5.40057C6.0875 5.40057 6.0675 5.44057 6.02417 5.44057C5.98083 5.44057 5.96417 5.41057 5.96083 5.40057L5.9075 5.29391C5.64083 4.77057 5.22417 4.35391 4.70083 4.08724L4.59417 4.03391C4.59417 4.03391 4.55417 4.01391 4.55417 3.97057C4.55417 3.92724 4.58417 3.91057 4.59417 3.90724Z"
        fill="#555770"
      />
      <path
        d="M14.0975 4.21724L13.4508 3.57057C13.1908 3.31057 12.8442 3.16724 12.4775 3.16724C12.1108 3.16724 11.7642 3.31057 11.5042 3.57057L4.58083 10.4939C4.58083 10.4939 4.57417 10.5039 4.57083 10.5106C4.53083 10.5539 4.4975 10.6006 4.47417 10.6539V10.6606L3.51083 12.9572C3.36083 13.3139 3.44417 13.7206 3.72083 13.9939C3.90083 14.1706 4.1375 14.2639 4.38083 14.2639C4.5075 14.2639 4.6375 14.2372 4.76083 14.1839L7.01083 13.1972L7.02417 13.1906C7.0775 13.1672 7.12083 13.1339 7.16083 13.0972C7.16417 13.0939 7.17083 13.0906 7.1775 13.0872L14.1008 6.16391C14.3608 5.90391 14.5042 5.55724 14.5042 5.19057C14.5042 4.82391 14.3608 4.47724 14.1008 4.21724H14.0975ZM6.82083 12.0272L5.64083 10.8472L10.8275 5.66057L12.0075 6.84057L6.82083 12.0272ZM13.3908 5.45724L12.7142 6.13391L11.5342 4.95391L12.2108 4.27724C12.3508 4.13391 12.6008 4.13391 12.7442 4.27724L13.3908 4.92391C13.4608 4.99391 13.5008 5.09057 13.5008 5.19057C13.5008 5.29057 13.4608 5.38391 13.3908 5.45724Z"
        fill="#555770"
      />
    </svg>
  );
}
