import React from 'react';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import LabelEditableWithKeyboard from './LabelEditableWithKeyboard.tsx';
import BulletedListItemEditableWithKeyboard from './BulletedListItemEditableWithKeyboard.tsx';
import NumberedListItemEditableWithKeyboard from './NumberedListItemEditableWithKeyboard.tsx';
import HeadingEditableWithKeyboard from './HeadingEditableWithKeyboard.tsx';
import cx from 'classnames';
import styles from './SelectableBlock.module.scss';
import BlockActions from './BlockActions.ts';
import { TextBlock } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.ts';
import ParagraphEditableWithKeyboard from './ParagraphEditableWithKeyboard.js';

type TextBlockEditableProps = {
  isInBlockSelection: boolean;
  className?: string;
  linkables: Linkable[];
  formattingMenu: React.ReactNode;
} & BlockActions<TextBlock>;

const TextBlockEditable = React.forwardRef<HTMLElement, TextBlockEditableProps>(
  function TextBlockEditable(
    {
      block,
      isInBlockSelection,
      className,
      selection,
      linkables,
      formattingMenu,
      ...otherProps
    },
    forwardedRef,
  ) {
    const blockProps = {
      ...otherProps,
      className: cx(
        className,
        styles.selectableBlock,
        isInBlockSelection && styles.selectableBlock_selected,
      ),
      ref: mergeRefs([forwardedRef]),
      value: block,
      selection,
      linkables,
    };

    switch (block.type) {
      case 'paragraph':
        return (
          <ParagraphEditableWithKeyboard
            {...blockProps}
            value={block}
            formattingMenu={formattingMenu}
          />
        );
      case 'label': {
        return (
          <LabelEditableWithKeyboard
            {...blockProps}
            value={block}
            formattingMenu={formattingMenu}
          />
        );
      }
      case 'bulleted-list-item': {
        return (
          <BulletedListItemEditableWithKeyboard
            {...blockProps}
            value={block}
            formattingMenu={formattingMenu}
          />
        );
      }
      case 'numbered-list-item': {
        return (
          <NumberedListItemEditableWithKeyboard
            {...blockProps}
            value={block}
            formattingMenu={formattingMenu}
          />
        );
      }
      case 'heading': {
        return (
          <HeadingEditableWithKeyboard
            {...blockProps}
            value={block}
            formattingMenu={formattingMenu}
          />
        );
      }
    }
  },
);

export default TextBlockEditable;
