import { cx } from 'styling';
import styles from './AddBlockMenuItem.module.scss';
import { Icon, IconName } from 'icons';
import { useEffect, useRef } from 'react';

type AddBlockMenuItemProps = {
  iconName: IconName;
  label: string;
  description: string;
  selected: boolean;
};

const AddBlockMenuItem = ({
  iconName,
  label,
  description,
  selected,
  ...otherProps
}: AddBlockMenuItemProps & React.HTMLAttributes<HTMLButtonElement>) => {
  const divRef = useRef<HTMLButtonElement>(null);

  const scrollToElement = () => {
    const { current } = divRef;
    if (current) {
      current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (selected) {
      scrollToElement();
    }
  }, [selected]);

  return (
    <button
      ref={divRef}
      {...otherProps}
      data-testid={`add-block-menu-item-${iconName}`}
      className={cx(styles.addBlockMenuItem, selected ? styles.selected : '')}
    >
      <div className={styles.addBlockMenuItem_image}>
        <Icon name={iconName} />
      </div>
      <div className={styles.addBlockMenuItem_text}>
        <div className={styles.addBlockMenuItem_label}>{label}</div>
        <div className={styles.addBlockMenuItem_description}>{description}</div>
      </div>
    </button>
  );
};

export default AddBlockMenuItem;
