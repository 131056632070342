import ButtonWithIcon from '../../../../../../design-system/atoms/ButtonWithIcon.js';
import { isNotPendingUser } from '../../../../../../types/User.js';
import CommentThread from './../CommentThread.js';
import styles from './CommentsSection.module.scss';
import NewCommentForm from './../NewCommentForm.js';
import { CommentsStateForSection } from '../useComments/useComments.js';
import { AnimatePresence, motion } from 'framer-motion';

export type CommentsProps = {
  className?: string;
  state: CommentsStateForSection;
};

const CommentMotion: React.FC<React.ComponentProps<typeof motion.div>> = ({
  children,
  ...props
}) => (
  <motion.div
    {...props}
    layout
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export function CommentsSection({
  state: {
    userName,
    viewers,
    comments,
    postComment,
    sectionTitle,
    isNewCommentFormOpen,
    cancelNewComment,
    setCommentSectionRef,
    openNewComment,
    scrollSectionIntoViewIfNeeded,
    canAddNewComment,
    setCommentElementRef,
    getSelectedCommentThreadId,
    selectCommentThread,
  },
}: CommentsProps) {
  return (
    <div
      ref={setCommentSectionRef}
      onClick={scrollSectionIntoViewIfNeeded}
      data-testid="comments-section"
    >
      <CommentMotion className={styles.commentsSection__header}>
        <span className={styles.commentsSection__sectionTitle}>
          {sectionTitle}
        </span>
        {canAddNewComment && (
          <ButtonWithIcon
            className={styles.commentsSection__addComment}
            iconName="addComment"
            position="left"
            size="medium"
            color="transparent"
            onClick={openNewComment}
          >
            Add Comment
          </ButtonWithIcon>
        )}
      </CommentMotion>
      <div className={styles.commentsSection__commentsList}>
        <AnimatePresence initial={false}>
          {canAddNewComment && isNewCommentFormOpen && (
            <CommentMotion>
              <NewCommentForm
                userName={userName}
                viewers={viewers}
                onCancel={() => {
                  cancelNewComment();
                }}
                onSubmit={(comment) =>
                  postComment(comment.content, comment.type === 'DirectMessage')
                }
              />
            </CommentMotion>
          )}
          {comments.map((comment) => {
            const availableViewerTags = viewers
              .filter(isNotPendingUser)
              .map((u) => ({
                userId: u.id,
                displayName: `${u.firstName} ${u.lastName}`,
              }));

            const viewersForComment =
              comment.type === 'DirectMessage'
                ? availableViewerTags.filter((viewer) =>
                    comment.viewers.includes(viewer.userId),
                  )
                : availableViewerTags;

            return (
              <CommentMotion key={comment.id}>
                <CommentThread
                  comment={comment}
                  commentReplies={comment.replies}
                  commentViewers={viewersForComment}
                  resolved={comment.resolved}
                  isDM={comment.type === 'DirectMessage'}
                  setCommentElementRef={setCommentElementRef}
                  selectedCommentThreadId={getSelectedCommentThreadId()}
                  selectCommentThread={selectCommentThread}
                />
              </CommentMotion>
            );
          })}
          {comments.length === 0 && !isNewCommentFormOpen && (
            <CommentMotion key="no-comments">
              <div className={styles.commentsSection__empty}>No comments.</div>
            </CommentMotion>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
