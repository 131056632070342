import React from 'react';
import styles from './File.module.scss';
import cx from 'classnames';
import { Icon } from 'icons';

type FileProps = {
  className?: string;
  filename: string;
  clickable?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const File = React.forwardRef<HTMLDivElement, FileProps>(function File(
  { className, filename, clickable, ...otherProps },
  forwardedRef,
) {
  return (
    <div
      {...otherProps}
      ref={forwardedRef}
      className={cx(styles.file, clickable && styles.file_clickable, className)}
    >
      <Icon name="paperclip" />
      <span>{filename}</span>
      {clickable && (
        <Icon name="download" className={styles.file__downloadIcon} />
      )}
    </div>
  );
});

export default File;
