import { useState } from 'react';
import TextFormattingMenu from '../../../../design-system/organisms/TextFormattingMenu.tsx';
import { TextBlock } from 'editor-content/Block.js';
import { Editor } from 'editor-content/TextNode.js';
import { useUserInfo } from '../../../../services/useUserInfo.tsx';
import SelectionCommentsMenu from '../comments/components/SelectionCommentsMenu.tsx';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import createZeckEditorTurnIntoables from './createZeckEditorTurnIntoables.ts';
import Linkable from 'editor-content/html/Linkable.ts';

type TextFormattingMenuWithCommentsProps = {
  children?: never;
  blockDisplayName: string;
  onTurnInto(type: TextBlock['type']): void;
  turnIntoEnabled: boolean;
  linkEnabled: boolean;
  linkables: Linkable[];
  otherActions?: React.ReactNode;
  onAddSelectionComment: (content: CommentContentNode[]) => Promise<void>;
  companyId: string;
  zeckId: string;
  sectionId: string;
  onAddLink(link: Editor.LinkType): void;
};

const TextFormattingMenuWithComments: React.FunctionComponent<
  TextFormattingMenuWithCommentsProps
> = ({
  blockDisplayName,
  onTurnInto,
  turnIntoEnabled,
  linkEnabled,
  linkables,
  onAddSelectionComment,
  companyId,
  zeckId,
  sectionId,
  otherActions,
  onAddLink,
}) => {
  const [uiState, setUiState] = useState<'formatting' | 'commenting'>(
    'formatting',
  );

  const userInfo = useUserInfo();

  if (uiState === 'formatting') {
    return (
      <TextFormattingMenu
        linkables={linkables}
        onAddLink={linkEnabled ? onAddLink : undefined}
        onClickAddComment={() => {
          setUiState('commenting');
        }}
        blockDisplayName={blockDisplayName}
        turnIntoables={
          turnIntoEnabled ? createZeckEditorTurnIntoables(onTurnInto) : []
        }
        otherActions={otherActions}
      />
    );
  }

  if (!userInfo) return null;

  return (
    <SelectionCommentsMenu
      autofocus
      comments={[]}
      user={userInfo.user}
      onPostComment={(content) => onAddSelectionComment(content)}
      companyId={companyId}
      zeckId={zeckId}
      sectionId={sectionId}
    />
  );
};

export default TextFormattingMenuWithComments;
