import SelectionComment from '../../../../SelectionComment.js';

type SelectionCommentWithActions = SelectionComment & {
  actions: {
    update: (updatedComment: SelectionComment) => Promise<void>;
    remove: () => Promise<void>;
  };
};

export const selectionCommentWithActions = (
  selectionComment: SelectionComment,
  update: SelectionCommentWithActions['actions']['update'],
  remove: SelectionCommentWithActions['actions']['remove'],
): SelectionCommentWithActions => ({
  ...selectionComment,
  actions: { update, remove },
});

export default SelectionCommentWithActions;
