import usePrevious from '../junkDrawer/usePrevious.js';
import { useEffect } from 'react';

// it kinda sucks that selection management is split between the block components and this hook
// this might point to a need to have selection management at a higher level in the component tree
const useSetBrowserSelectionWhenNull = <T>(selection: T | null) => {
  const prevSelection = usePrevious(selection);

  useEffect(() => {
    if (prevSelection !== null && selection === null) {
      const browserSelection = window.getSelection();
      if (browserSelection && browserSelection.rangeCount > 0) {
        browserSelection.removeAllRanges();
      }
    }
  }, [prevSelection, selection]);
};

export default useSetBrowserSelectionWhenNull;
