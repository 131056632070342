import { TableBlock, createTableBlock } from 'editor-content/TableBlock.ts';
import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../../api/endpoints/createCredentialApi.ts';
import {
  Integration,
  IntegrationListItem,
} from '../../../../../../api/endpoints/createIntegrationApi.ts';
import GoogleDocumentPicker from './GoogleDocumentPicker.tsx';
import useLoadGoogleAPIs from '../utils/google/useLoadGoogleAPIs.ts';
import IntegrationLoader, {
  ZeckCommunicationLoader,
} from '../IntegrationLoader.tsx';
import GoogleSheetPicker from './GoogleSheetPicker.tsx';
import createTableFromGoogleSheet from '../utils/google/createTableFromGoogleSheet.ts';
import { DocumentMeta } from '../integrationModalTypes.ts';
import { makeTokenObjectFromCredential } from '../utils/google/googleUtils.ts';
import PickCredential from '../PickCredential.tsx';

export type GoogleIntegrationUIState = { integrationType: 'google' } & (
  | { type: 'SELECT_CREDENTIAL'; errorCredential?: ErrorCredentialResponse }
  | { type: 'LOADING_SHEET' }
  | {
      type: 'PICKING_DOCUMENT';
      credential: ValidCredentialResponse;
      sheetError: SheetError | null;
    }
  | {
      type: 'PICKING_SHEET';
      credential: ValidCredentialResponse;
      documentMeta: DocumentMeta;
      sheetError: SheetError | null;
    }
);

type SheetError = 'COULD_NOT_GET_SHEET_DATA';

type GoogleIntegrationFlowProps = {
  onClose: () => void;
  handleIntegrationData: (
    integrationData: Omit<Integration, 'id'>,
  ) => Promise<IntegrationListItem>;
  addIntegration: (integration: IntegrationListItem) => void;
  onCreateTable: (table: TableBlock) => void;
  uiState: GoogleIntegrationUIState;
  setUIState: (uiState: GoogleIntegrationUIState) => void;
};

export const GoogleIntegrationFlow = ({
  onClose,
  handleIntegrationData,
  onCreateTable,
  addIntegration,
  uiState,
  setUIState,
}: GoogleIntegrationFlowProps) => {
  const googleApis = useLoadGoogleAPIs();

  if (!googleApis) {
    return <IntegrationLoader />;
  }

  switch (uiState.type) {
    case 'SELECT_CREDENTIAL': {
      return (
        <PickCredential
          type="google"
          onClose={onClose}
          errorCredential={uiState.errorCredential}
          onErrorCredential={(credential) => {
            setUIState({
              integrationType: 'google',
              type: 'SELECT_CREDENTIAL',
              errorCredential: credential,
            });
          }}
          onPickCredential={(credential) => {
            googleApis.gapi.auth.setToken(
              makeTokenObjectFromCredential(credential.accessToken),
            );
            setUIState({
              integrationType: 'google',
              type: 'PICKING_DOCUMENT',
              credential: credential,
              sheetError: null,
            });
          }}
        />
      );
    }
    case 'PICKING_DOCUMENT': {
      const onPickDocument = (documentMeta: DocumentMeta) => {
        setUIState({
          integrationType: 'google',
          type: 'PICKING_SHEET',
          credential: uiState.credential,
          documentMeta,
          sheetError: null,
        });
      };

      return (
        <GoogleDocumentPicker
          {...{
            googleApis,
            accessToken: uiState.credential.accessToken,
            onClose,
            onPickDocument,
          }}
        />
      );
    }

    case 'PICKING_SHEET': {
      return (
        <GoogleSheetPicker
          {...{
            documentMeta: uiState.documentMeta,
            onClose,
            gapi: googleApis.gapi,
            onPickSheet: async (sheetData, range) => {
              setUIState({
                integrationType: 'google',
                type: 'LOADING_SHEET',
              });

              const integration = await handleIntegrationData({
                credentialId: uiState.credential.id,
                provider: 'google',
                providerMeta: {
                  documentId: uiState.documentMeta.documentId,
                  documentName: uiState.documentMeta.documentName,
                  selectionName:
                    range ?? sheetData.properties?.title ?? '(Unknown sheet)',
                  selectionId: sheetData.properties?.sheetId || 0, // this ain't legit
                  selectionType: range ? 'range' : 'sheet',
                },
              });

              onCreateTable(
                createTableBlock(
                  createTableFromGoogleSheet(sheetData),
                  integration.id,
                ),
              );

              addIntegration(integration);
            },
          }}
        />
      );
    }
    case 'LOADING_SHEET': {
      return <ZeckCommunicationLoader />;
    }
  }
};
