import createApi, { ApiService } from './createApi.ts';
import useAccessToken from './useAccessTokenState.ts';
import ApiContext from './ApiContext.ts';
import mapValues from 'lodash/mapValues.js';
import { useEffect, useState } from 'react';
import * as logger from '../logging/logger.ts';
import makeLambdaApiRequest from './makeLambdaApiRequest.ts';
import makeAnalyticsApiRequest from './makeAnalyticsApiRequest.ts';
import makeDeletionRequestApiRequest from './makeDeletionRequestApiRequest.ts';
import { hasStatus, RequestError } from './makeMakeApiRequest.ts';
import OldVersionModal from './OldVersionModal.tsx';
import { version } from '../version.ts';
import PublishedCommentApiClient from './endpoints/PublishedCommentApiClient.js';

type BasicUserInfo = {
  invitationCode: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export type SignUpData =
  | (BasicUserInfo & { companyName: string })
  | BasicUserInfo;

export type SignInData = {
  email: string;
  password: string;
};

export type Session = {
  authenticated: boolean;
  setAccessToken: (token: string) => void;
  signIn: (userData: SignInData) => Promise<string | null>;
  signUp: (
    userData: SignUpData,
  ) => Promise<'SUCCESS' | 'INVALID_CREDENTIALS' | 'EMAIL_TAKEN'>;
  signOut: () => void;
  signOutNoRedirect: () => void;
};

export type ApiSessionProps = {
  children: (session: Session) => JSX.Element;
};

export default function ApiSession({ children }: ApiSessionProps) {
  const { accessToken, setAccessToken, isTokenExpired } = useAccessToken();
  const [isOldVersion, setIsOldVersion] = useState(false);

  useEffect(() => {
    logger.setUiVersion(version);
  }, []);

  useEffect(() => {
    if (!accessToken) {
      logger.setUserId(null);
      logger.setCompanyId(null);
    }
  }, [accessToken]);

  const api = mapValues(
    createApi(
      makeLambdaApiRequest,
      makeAnalyticsApiRequest,
      makeDeletionRequestApiRequest,
      PublishedCommentApiClient,
      accessToken,
    ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Fn extends (...args: any[]) => any>(fn: Fn) =>
      (...args: Parameters<Fn>): ReturnType<Fn> => {
        if (isTokenExpired()) {
          setAccessToken(null);
          window.location.assign(window.location.href);
          return new Promise(() => {
            // Return a promise that won't resolve
          }) as unknown as ReturnType<Fn>;
        } else {
          return fn(...args).catch((error: RequestError) => {
            if (error.status === 401) {
              setAccessToken(null);
              window.location.assign(window.location.href);
              return new Promise(() => {
                // Return a promise that won't resolve
              }) as unknown as ReturnType<Fn>;
            } else if (error.status === 426) {
              setIsOldVersion(true);
              return new Promise(() => {
                // Return a promise that won't resolve
              }) as unknown as ReturnType<Fn>;
            } else {
              return Promise.reject(error);
            }
          });
        }
      },
  ) as ApiService;

  return (
    <ApiContext.Provider value={api}>
      <>
        <OldVersionModal
          isOpen={isOldVersion}
          onClose={() => {
            window.location.reload();
          }}
        />
        {children({
          authenticated: Boolean(accessToken),
          setAccessToken,
          async signIn({ email, password }) {
            try {
              const { accessToken } = await makeLambdaApiRequest('/login', {
                method: 'POST',
                body: JSON.stringify({
                  email,
                  password,
                }),
              });

              setAccessToken(accessToken);

              return accessToken;
            } catch (error) {
              return null;
            }
          },
          async signUp(userData) {
            try {
              const { accessToken } = await makeLambdaApiRequest('/signup', {
                method: 'POST',
                body: JSON.stringify({ ...userData }),
              });
              setAccessToken(accessToken);
              return 'SUCCESS';
            } catch (error) {
              if (hasStatus(error)) {
                if (error.status === 400) {
                  return 'INVALID_CREDENTIALS';
                } else if (error.status === 409) {
                  return 'EMAIL_TAKEN';
                } else {
                  throw error;
                }
              } else {
                throw error;
              }
            }
          },
          signOut() {
            setAccessToken(null);
            window.location.assign(window.location.origin);
          },
          signOutNoRedirect() {
            setAccessToken(null);
          },
        })}
      </>
    </ApiContext.Provider>
  );
}
