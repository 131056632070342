import useApi from '../../../api/useApi.ts';
import { CompanyLite, isNotPendingUser, User } from '../../../types.ts';
import MeetingMinutesEditorPageView from './MeetingMinutesEditorPageView.tsx';
import { foldResult, pipe, Result } from '../../../result/Result.ts';
import { useRef } from 'react';
import { isUpdateConflictErrorResponse } from '../../../api/endpoints/createSectionApi.ts';
import ConflictModal from '../../zeck/ConflictModal.tsx';
import { useRequiredParams } from '../../../routing/useRequiredParams.ts';
import useFetch from '../../../services/useFetch/useFetch.ts';
import {
  MeetingMinutes,
  MinutesSignature,
} from '../../../api/endpoints/createMeetingMinutesApi.ts';
import MeetingMinutesArchivePageView from './MeetingMinutesArchivePageView.tsx';
import MeetingMinutesLoading from '../MeetingMinutesLoading.tsx';
import { Navigate } from 'react-router-dom';
import useFetchMeetingMinutes from '../useFetchMeetingMinutes.ts';

type MeetingMinutesPageProps = {
  company: CompanyLite;
  user: User;
};

const useFetchMeetingMinutesWithActions = (
  meetingMinutesId: string,
): {
  result: Result<unknown, MeetingMinutes>;
  updateMeetingMinutes: (
    meetingMinutes: Partial<MeetingMinutes> &
      Pick<MeetingMinutes, 'id' | 'version'>,
  ) => Promise<void>;
  signMeetingMinutes: (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ) => Promise<void>;
} => {
  const { updateMeetingMinutes, signMeetingMinutes } = useApi();

  const { result, mutation } = useFetchMeetingMinutes(meetingMinutesId);

  return {
    result,
    updateMeetingMinutes: mutation((newMinutes) =>
      updateMeetingMinutes(newMinutes),
    ),
    signMeetingMinutes: mutation((meetingMinutes, signature) =>
      signMeetingMinutes(
        {
          id: meetingMinutes.id,
          version: meetingMinutes.version,
        },
        signature,
      ),
    ),
  };
};

const MeetingMinutesEditorPage = ({
  company,
  user,
}: MeetingMinutesPageProps) => {
  const { getCompany } = useApi();

  const { meetingMinutesId } = useRequiredParams('meetingMinutesId');
  const scrollContainer = useRef<HTMLDivElement>(null);
  const {
    result: meetingMinutesResult,
    updateMeetingMinutes,
    signMeetingMinutes,
  } = useFetchMeetingMinutesWithActions(meetingMinutesId);

  const signMeetingMinutesAndRetainScroll = async (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ) => {
    const scrollPosition = scrollContainer.current?.scrollTop;

    await signMeetingMinutes(meetingMinutes, signature);
    // set scroll position back to where it was, wait one tick for the DOM to update
    setTimeout(
      () => scrollContainer.current?.scrollTo(0, scrollPosition || 0),
      1,
    );
  };

  const companyResult = useFetch(() => getCompany(company.id), [company.id]);

  switch (meetingMinutesResult.type) {
    case 'loading':
      return <MeetingMinutesLoading />;
    case 'error':
      if (isUpdateConflictErrorResponse(meetingMinutesResult.error)) {
        return (
          <ConflictModal
            isOpen={true}
            user={meetingMinutesResult.error.body.updatedBy}
            description="is editing these minutes right now too. You both risk losing work if you
            continue to edit the same minutes at the same time. You should wait
            until they've completed their editing to continue."
          />
        );
      }
      return <Navigate to="/404" />;
  }

  if (meetingMinutesResult.data.archivedHtml) {
    return (
      <MeetingMinutesArchivePageView
        scrollContainer={scrollContainer}
        meetingMinutes={meetingMinutesResult.data}
        company={company}
        signMeetingMinutes={signMeetingMinutes}
        user={user}
      />
    );
  }

  const activeUsers = pipe(
    companyResult,
    foldResult({
      loading: () => [],
      error: () => [],
      success: (c) => c.users.filter(isNotPendingUser),
    }),
  );

  return (
    <MeetingMinutesEditorPageView
      meetingMinutes={meetingMinutesResult.data}
      scrollContainer={scrollContainer}
      activeUsers={activeUsers}
      company={company}
      signMeetingMinutes={signMeetingMinutesAndRetainScroll}
      user={user}
      onUpdateMeetingMinutes={async ({ content: newContent }) => {
        await updateMeetingMinutes({
          ...meetingMinutesResult.data,
          content: newContent,
        });
      }}
    />
  );
};

export default MeetingMinutesEditorPage;
