import Toolbar, { ToolbarButton, ToolbarGroup } from './Toolbar.tsx';

type TableFormattingMenuProps = {
  onClickSync: () => void;
};

const TableFormattingMenu: React.FC<TableFormattingMenuProps> = ({
  onClickSync,
}) => {
  return (
    <Toolbar>
      <ToolbarGroup>
        <ToolbarButton
          iconName="refresh"
          onMouseDown={(e) => {
            // doing this to prevent changing active element focus
            e.preventDefault();
            e.stopPropagation();
            onClickSync();
          }}
          aria-label="sync table"
        >
          Sync
        </ToolbarButton>
      </ToolbarGroup>
    </Toolbar>
  );
};

export default TableFormattingMenu;
