import { TableBlock, compressTableBlock } from 'editor-content/TableBlock.js';
import { useRef, useState } from 'react';
import {
  CredentialResponse,
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../api/endpoints/createCredentialApi.ts';
import {
  Integration,
  IntegrationListItem,
  IntegrationListItemData,
} from '../../../api/endpoints/createIntegrationApi.ts';
import useApi from '../../../api/useApi.ts';
import { ModalContainer } from '../../../design-system/organisms/Modal.tsx';
import TableFormattingMenu from '../../../design-system/organisms/TableFormattingMenu.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import { User } from '../../../types.ts';
import createTableFromGoogleSheet from './addBlock/integrationsModal/utils/google/createTableFromGoogleSheet.ts';
import {
  apiKey,
  clientId,
} from './addBlock/integrationsModal/utils/google/googleEnvVariables.ts';
import useLoadGoogleAPIs, {
  GoogleApis,
} from './addBlock/integrationsModal/utils/google/useLoadGoogleAPIs.ts';

import { ZeckCommunicationLoader } from './addBlock/integrationsModal/IntegrationLoader.tsx';
import MissingSheetModalContent from './addBlock/integrationsModal/MissingSheetModalContent.tsx';
import ReconnectModalContent from './addBlock/integrationsModal/ReconnectModalContent.tsx';
import { makeTokenObjectFromCredential } from './addBlock/integrationsModal/utils/google/googleUtils.ts';
import { getArrayBufferOfOneDriveItem } from './addBlock/integrationsModal/utils/excel/getArrayBufferOfOneDriveItem.ts';
import { createExcelWorkbookData } from './addBlock/integrationsModal/utils/excel/createExcelWorkbookData.ts';
import createTableFromExcelWorksheet, {
  getDefinedNameData,
} from './addBlock/integrationsModal/utils/excel/createTableFromExcelWorksheet.ts';
import {
  ExcelIntegrationUIState,
  MicrosoftIntegrationFlow,
} from './addBlock/integrationsModal/microsoft/MicrosoftIntegrationsModalFlow.tsx';
import useToast from '../../../design-system/molecules/toast/useToast.ts';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import { logError } from '../../../logging/logger.js';

type TableFormattingExperienceProps = {
  block: TableBlock;
  getEl: () => HTMLElement | undefined;
  onReplaceTable: (block: TableBlock) => void;
  user: User;
  integrationData: IntegrationListItemData;
};

type MicrosoftErrorState =
  | {
      type: 'microsoft-error';
      credential: ValidCredentialResponse;
      errorType: 'documentNotFound';
    }
  | {
      type: 'microsoft-error';
      credential: ErrorCredentialResponse;
      errorType: 'credentialError';
    };

type BlockInteractiveRenderState =
  | { type: 'formatting' }
  | { type: 'syncing' }
  | {
      type: 'not-found';
      notFoundType: 'sheet' | 'rows';
      credential: ValidCredentialResponse;
      attemptedSheet: string;
    }
  | {
      type: 'google-error';
      credential: ErrorCredentialResponse;
      errorType: 'credentialError';
    }
  | MicrosoftErrorState;

const TableFormattingExperience: React.FC<TableFormattingExperienceProps> = ({
  block,
  user,
  integrationData,
  getEl,
  onReplaceTable,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>({ type: 'formatting' });
  const { fetchCredentialResponse: fetchCredential, updateIntegration } =
    useApi();

  const googleApiRef = useRef<GoogleApis | null>(null);
  const googleApis = useLoadGoogleAPIs((googleApis) => {
    googleApiRef.current = googleApis;
  });

  const { integrations, updateIntegration: updateClientIntegration } =
    integrationData;

  const integration = integrations.find(
    (i) => i.id === block.integrationId && i.userId === user.id,
  );

  // using this key as a way to force a reset of the state in the microsoft error flow
  const [microsoftUIKey, setMicrosoftUIKey] = useState(0);

  const { showToast, ...toast } = useToast();

  if (!integration) {
    return null;
  }

  if (!clientId || !apiKey) {
    return null;
  }

  const showMicrosoftError = (errorState: MicrosoftErrorState) => {
    setInteractiveState(errorState);
    setMicrosoftUIKey((k) => k + 1); // force reset of microsoft error flow state
  };

  function updateTableBlockFromSheet(
    sheet: gapi.client.sheets.Sheet,
    credential: ValidCredentialResponse,
  ) {
    let updatedTableBlock: TableBlock['data'] | undefined;
    try {
      updatedTableBlock = createTableFromGoogleSheet(sheet);
    } catch {}

    const attemptedSheet = sheet.properties?.title ?? 'Sheet';
    if (!updatedTableBlock) {
      setInteractiveState({
        type: 'not-found',
        credential: credential,
        notFoundType: 'rows',
        attemptedSheet: attemptedSheet,
      });
      return;
    }

    onReplaceTable(
      compressTableBlock(
        TableBlock(block.id, updatedTableBlock, block.integrationId),
      ),
    );

    setInteractiveState({ type: 'formatting' });
  }

  async function updateTableWithMicrosoftCredential(
    credential: ValidCredentialResponse,
    integration: IntegrationListItem,
  ) {
    try {
      const fileWithModified = await getArrayBufferOfOneDriveItem(
        credential.accessToken,
        integration.providerMeta.documentId,
        integration.providerMeta.driveId,
      );

      const workbookData = await createExcelWorkbookData(fileWithModified, {
        documentId: integration.providerMeta.documentId,
        documentName: integration.providerMeta.documentName,
      });

      const range =
        integration.providerMeta.selectionType === 'range'
          ? getDefinedNameData(
              workbookData.workbook,
              integration.providerMeta.selectionName,
            )
          : undefined;

      const worksheet = workbookData.workbook.getWorksheet(
        range ? range.sheetName : integration.providerMeta.selectionName,
      );

      const updatedTableBlock = createTableFromExcelWorksheet(
        worksheet,
        workbookData.valueTypes,
        range?.range,
      );

      onReplaceTable(
        compressTableBlock(
          TableBlock(block.id, updatedTableBlock, block.integrationId),
        ),
      );

      setInteractiveState({ type: 'formatting' });

      if (workbookData.documentMeta.lastModified) {
        showToast(
          `Synced to OneDrive file last modified at ${workbookData.documentMeta.lastModified.toLocaleString()}`,
          8000,
        );
      }
    } catch (e) {
      logError(e as Error);

      showMicrosoftError({
        type: 'microsoft-error',
        credential: credential,
        errorType: 'documentNotFound',
      });
    }
  }

  async function updateTableWithGoogleCredential(
    credential: ValidCredentialResponse,
    integration: IntegrationListItem,
  ) {
    let gapi: typeof window.gapi;
    if (!googleApis) {
      // this kinda sucks but possible to sync before google apis are loaded so using this to wait for them
      while (!googleApiRef?.current?.gapi) {
        await new Promise((r) => setTimeout(r, 1));
      }

      gapi = googleApiRef.current.gapi;
    } else {
      gapi = googleApis.gapi;
    }

    gapi.auth.setToken(makeTokenObjectFromCredential(credential.accessToken));

    let sheetData: gapi.client.sheets.Sheet | undefined;

    try {
      const sheetResponse = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: integration.providerMeta.documentId,
        ranges: [integration.providerMeta.selectionName],
        includeGridData: true,
      });

      sheetData = sheetResponse?.result?.sheets?.[0];
    } catch {}

    if (!sheetData) {
      setInteractiveState({
        type: 'not-found',
        notFoundType: 'sheet',
        credential: credential,
        attemptedSheet: integration.providerMeta.selectionName,
      });
      return;
    }

    updateTableBlockFromSheet(sheetData, credential);
  }

  const handleCredentialResponse = async (
    credential: CredentialResponse,
    integration: IntegrationListItem,
  ) => {
    switch (credential.status) {
      case 'error':
        if (credential.type === 'microsoft') {
          showMicrosoftError({
            type: 'microsoft-error',
            credential,
            errorType: 'credentialError',
          });
          return;
        }

        setInteractiveState({
          type: 'google-error',
          credential,
          errorType: 'credentialError',
        });
        return;
      case 'valid':
        break;
    }

    switch (credential.type) {
      case 'google':
        await updateTableWithGoogleCredential(credential, integration);
        break;
      case 'microsoft':
        await updateTableWithMicrosoftCredential(credential, integration);

        break;
      default:
        console.error('Unknown credential type', credential);
        throw new Error('Unknown credential type');
    }
  };

  return (
    <>
      {interactiveState.type === 'formatting' && (
        <HoverNextToPoint
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <TableFormattingMenu
            onClickSync={async () => {
              setInteractiveState({ type: 'syncing' });

              // fetch credential for integration with token
              const credential = await fetchCredential(
                integration.credentialId,
              );

              await handleCredentialResponse(credential, integration);
            }}
          />
        </HoverNextToPoint>
      )}
      <ModalContainer isOpen={interactiveState.type !== 'formatting'}>
        {interactiveState.type === 'syncing' && <ZeckCommunicationLoader />}
        {/* could eventually move this to the google integration flow */}
        {interactiveState.type === 'not-found' && (
          <MissingSheetModalContent
            integrationProviderMeta={integration.providerMeta}
            missingDataType={interactiveState.notFoundType}
            onPickSheet={async (sheet, rangeName) => {
              const returnedIntegration = await updateIntegration({
                ...integration,
                providerMeta: {
                  documentId: integration.providerMeta.documentId,
                  documentName:
                    sheet.properties?.title ??
                    integration.providerMeta.documentName,
                  selectionName:
                    rangeName ??
                    sheet.properties?.title ??
                    integration.providerMeta.selectionName,
                  selectionType: rangeName ? 'range' : 'sheet',
                  selectionId: sheet.properties?.sheetId ?? 0,
                },
              });

              updateClientIntegration({
                ...returnedIntegration,
                userId: integration.userId,
              });

              updateTableBlockFromSheet(sheet, interactiveState.credential);
            }}
            credential={interactiveState.credential}
            onClose={() => {
              setInteractiveState({ type: 'formatting' });
            }}
          />
        )}

        {interactiveState.type === 'microsoft-error' && (
          <MicrosoftErrorFlow
            key={microsoftUIKey}
            onClose={() => {
              setInteractiveState({ type: 'formatting' });
            }}
            handleIntegrationData={async (data) => {
              const returnedIntegration = await updateIntegration({
                ...integration,
                providerMeta: data.providerMeta,
              });

              updateClientIntegration({
                ...returnedIntegration,
                userId: integration.userId,
              });

              return returnedIntegration;
            }}
            addIntegration={() => {}}
            onCreateTable={(tableBlock) => {
              onReplaceTable(compressTableBlock(tableBlock));

              setInteractiveState({ type: 'formatting' });
            }}
            initialState={
              interactiveState.errorType === 'documentNotFound'
                ? {
                    type: 'DOCUMENT_NOT_FOUND',
                    integrationType: 'excelOnedrive',
                    accessToken: interactiveState.credential.accessToken,
                    credentialId: integration.credentialId,
                  }
                : {
                    type: 'SELECT_CREDENTIAL',
                    integrationType: 'excelOnedrive',
                    errorCredential: interactiveState.credential,
                  }
            }
            onReconnected={async (credential) => {
              setInteractiveState({ type: 'syncing' });
              await handleCredentialResponse(credential, integration);
            }}
          />
        )}

        {interactiveState.type === 'google-error' && (
          <ReconnectModalContent
            onReconnected={async (credential) => {
              await handleCredentialResponse(credential, integration);
            }}
            credential={interactiveState.credential}
            onClose={() => {
              setInteractiveState({ type: 'formatting' });
            }}
          />
        )}
      </ModalContainer>
      <Toast {...toast} />
    </>
  );
};

export default TableFormattingExperience;

type MicrosoftErrorFlowProps = {
  onClose: () => void;
  handleIntegrationData: (
    integrationData: Omit<Integration, 'id'>,
  ) => Promise<IntegrationListItem>;
  addIntegration: (integration: IntegrationListItem) => void;
  onCreateTable: (table: TableBlock) => void;
  initialState: ExcelIntegrationUIState;
  onReconnected: (
    credential: ValidCredentialResponse | ErrorCredentialResponse,
  ) => void;
};

const MicrosoftErrorFlow = ({
  initialState,
  onClose,
  onReconnected,
  handleIntegrationData,
  addIntegration,
  onCreateTable,
}: MicrosoftErrorFlowProps) => {
  const [uiState, setUIState] = useState<ExcelIntegrationUIState>(initialState);

  return (
    <MicrosoftIntegrationFlow
      uiState={uiState}
      setUIState={setUIState}
      onClose={onClose}
      onReconnected={onReconnected}
      handleIntegrationData={handleIntegrationData}
      addIntegration={addIntegration}
      onCreateTable={onCreateTable}
    />
  );
};
