import { createContext } from 'react';
import createApi, { ApiService } from './createApi.js';
import makeLambdaApiRequest from './makeLambdaApiRequest.js';
import makeAnalyticsApiRequest from './makeAnalyticsApiRequest.js';
import makeDeletionApiRequest from './makeDeletionRequestApiRequest.js';
import PublishedCommentApiClient from './endpoints/PublishedCommentApiClient.js';

export default createContext<ApiService>(
  createApi(
    makeLambdaApiRequest,
    makeAnalyticsApiRequest,
    makeDeletionApiRequest,
    PublishedCommentApiClient,
    null,
  ),
);
